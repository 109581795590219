import styled, { css } from 'styled-components/macro';
import { TraderIdFieldContainerStyle } from './types';

const TraderIdFieldContainer = styled.div<TraderIdFieldContainerStyle>`
    ${(props) => {
        let paddingBottom = '0px';
        if (props.showErrorMessage) {
            paddingBottom = '8px';
        }
        return css`
            padding-bottom: ${paddingBottom};
        `;
    }}
    & div {
        :first-of-type {
            padding: 0px;
            margin: 0px 0px 4px 0px;
        }
        :last-of-type {
            width: 88px;
            height: 28px;
            margin: 0px;
        }
        & input {
            ${(props) => {
                let backgroundColor = '#ffffff';
                let border = 'solid 1px #ababab;';
                if (props.showErrorMessage) {
                    backgroundColor = 'rgba(227, 39, 71, 0.12)';
                    border = 'solid 1px #e32747;';
                }
                return css`
                    background-color: ${backgroundColor};
                    border: ${border};
                `;
            }}
        }
    }
    & span {
        height: 17px;
        font-size: 12px;
        font-weight: 600;
        & #validationMsgIcon {
            height: 16px;
            font-size: 16px;
            font-weight: normal;
            margin: 1px 3.3px 0px 0.7px;
        }
    }
`;

export { TraderIdFieldContainer };

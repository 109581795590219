import styled from 'styled-components/macro';

const AllocationDestinationContainer = styled.div`
    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0px 0px 12px 0px;
        & > span {
            width: 100px;
            height: 17px;
            font-size: 12px;
            font-weight: 600;
            color: #666666;
            margin: 0px 0px 0px 0px;
            padding: 0px;
        }
        & > label {
            margin: 0px 0px 0px 15px;
            & > div:first-child {
                display: none;
            }
            & > div:last-child {
                width: 90px;
                height: 28px;
                margin: 0px 0px 0px 0px;
            }
        }
        & > button {
            width: 130px;
            padding: 7px 6px 7px 8px;
            margin: 0px 0px 0px 15px;
        }
    }
    & #allocation-destination-too-many-therms-error-message {
        height: 17px;
        font-size: 12px;
        font-weight: 600;
        margin: 8px 0px 10px 0px;
        & #validationMsgIcon {
            height: 16px;
            font-size: 16px;
            margin: 1px 3.3px 0px 0.7px;
        }
    }
`;

export { AllocationDestinationContainer };

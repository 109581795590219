import { GAS_ALLOCATION_TYPE } from './GAS_ALLOCATION_TYPE';

export enum Status {
    SUCCESS,
    FAILURE
}

export type AligneReturnField = {
    fieldName: string;
    value: string;
    comment: string;
};

export type AligneImportResult = {
    status: Status;
    statusMessage?: string;
    errorMessage?: string;
    warningMessage?: string;
    successMessage?: string;
    aligneFieldsReturned: Array<AligneReturnField>;
};

// Type used to signal to websocket consumers what allocation result is being sent.
export type AllocationAligneImportResult = {
    correlationId: string;
    allocationType: GAS_ALLOCATION_TYPE;
    importResults: AligneImportResult[];
};

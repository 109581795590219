import { BUY_SELL, TRADE_ALLOCATION_DESTINATION } from '../../../common/types';

export type OtcTradesVolumeDestination = { volumeTherms: number; allocatedTo: TRADE_ALLOCATION_DESTINATION };

export enum OTC_SEGMENT {
    // eslint-disable-next-line no-unused-vars
    EDF = 'EDF'
}

export enum OTC_COUNTERPARTY {
    // eslint-disable-next-line no-unused-vars
    EDFT = 'EDFT'
}

export type OtcTradesPostRequest = {
    traderId: string;
    tradeDate: string;
    deliveryStartDate: string;
    deliveryEndDate: string;
    product: string;
    buySell: BUY_SELL;
    segment: OTC_SEGMENT;
    counterparty: OTC_COUNTERPARTY;
    brokerage: boolean;
    priceGBP: number;
    volumeTherms: number;
    allocationControl: string;
    destination?: TRADE_ALLOCATION_DESTINATION;
    isSplitWithWbb?: boolean;
    isSplitWithPortfolio?: boolean;
};

import styled from 'styled-components/macro';
import { ValidationMessageStyle, IconStyle } from './types';

const ValidationMessageContainer = styled.span<ValidationMessageStyle>`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    letter-spacing: 0.8px;
    color: ${(props) => props.handleMessageType(props.messageType)};
`;

const Icon = styled.span<IconStyle>``;

export { ValidationMessageContainer, Icon };

import styled from 'styled-components/macro';

const TradesPanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-left: 1px solid #b9b9b9;
    width: 360px;
    & #ice-trades-panel-placeholder-message {
        text-align: center;
        color: #666666;
    }
    & #ice-trades-panel-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        margin: 0px;
        padding: 12px 11.5px 12px 12px;
    }
    & #ice-trades-panel {
        min-height: 0px;
        & #ice-trades-panel-tablist {
            margin: 0px 12px;
            & li {
                font-size: 11px;
                font-weight: 600;
            }
        }
        & #ice-trades-panel-tabpanel {
            overflow-y: scroll;
            ::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }
`;

export { TradesPanelWrapper };

import React, { useEffect, useState } from 'react';
import { Button, Paragraph } from '@edfenergy/shift-desk-wallace';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectSelectedIceTrades, setSelectedIceTrades } from '../../iceTradesSlice';
import { selectTraderId, selectTraderIdError } from '../../../user/userSlice';

import {
    useGetIceTradesUnallocatedQuery,
    usePostIceTradesAllocateFullMutation,
    usePostIceTradesAllocateSplitMutation
} from '../../../../services/iceTradesApi';

import { IceTrade } from '../../types';
import {
    ConfirmButtonWrapper,
    ErrorMessageContainer,
    IceTradesAllocationPanelContainer,
    IceTradesAllocationPanelWrapper,
    IceTradesTotalThermsWrapper,
    AllocationDestinationElementsContainer
} from './styles';
import { IceTradesPostAllocateFullRequest, IceTradesPostAllocateSplitRequest } from '../../types/IceTrade';
import { useGetZapiResponseQuery } from '../../../../services/zapiResponseApi';
import { selectWebSocketReconnect, setWebSocketReconnect } from '../../../../services/zapiResponseSlice';
import { setActionMessage } from '../../../actionMessages/actionMessagesSlice';
import { mapIceTradesAllocationApiErrorToActionMessages } from '../../../actionMessages/maps';

import { ValidationMessage } from '../../../../common/components/ValidationMessage';
import { AllocationDestinationItem } from '../AllocationDestinationItem';
import {
    TradeAllocationDestinationText,
    TradeAllocationDestinationsTextList,
    IceTradesPostResponse
} from '../../../../common/types';
import { ActionMessage } from '../../../actionMessages/types';
import { generatePostIceTradesAllocateSplitRequest } from './generatePostIceTradesAllocateSplitRequest';

const IceTradesAllocationPanel: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedIceTrades: IceTrade[] = useAppSelector(selectSelectedIceTrades);
    const traderId = useAppSelector(selectTraderId);
    const traderIdError = useAppSelector(selectTraderIdError);
    const webSocketReconnect = useAppSelector(selectWebSocketReconnect);

    const { data, error, isError, isSuccess, isLoading } = useGetZapiResponseQuery(webSocketReconnect);

    const { refetch } = useGetIceTradesUnallocatedQuery();
    const [postIceTradesAllocateFull, { isLoading: isUpdatingFull }] = usePostIceTradesAllocateFullMutation();
    const [postIceTradesAllocateSplit, { isLoading: isUpdatingSplit }] = usePostIceTradesAllocateSplitMutation();

    const [totalThermsSelected, setTotalThermsSelected] = useState<number>(0);

    const [splitAllocationThermsAllocated, setSplitAllocationThermsAllocated] = useState(0);
    const [splitAllocationDestinationThermCounts, setSplitAllocationDestinationThermCounts] = useState(
        TradeAllocationDestinationsTextList?.map((iceTradesAllocationDestination: TradeAllocationDestinationText) => {
            return { ...iceTradesAllocationDestination, thermsAllocated: 0, isTooManyTherms: false };
        })
    );
    const [allocationRemainingTherms, setAllocationRemainingTherms] = useState(totalThermsSelected);
    const [allocationRemainingThermsError, setAllocationRemainingThermsError] = useState(false);

    const onConfirmAllocateSplitClick = (traderIdError: boolean) => {
        if (data?.isWebSocketConnected === false) {
            dispatch(setWebSocketReconnect(!webSocketReconnect));
        }
        if (!traderIdError && allocationRemainingTherms === 0 && selectedIceTrades.length > 0) {
            selectedIceTrades.forEach((selectedIceTrade) => {
                const request = generatePostIceTradesAllocateSplitRequest(
                    selectedIceTrade,
                    totalThermsSelected,
                    splitAllocationDestinationThermCounts,
                    traderId
                );

                // this function acts as a custom type-guard
                const isOnlySingleDestinationAllocated = (
                    request: IceTradesPostAllocateSplitRequest | IceTradesPostAllocateFullRequest
                ): request is IceTradesPostAllocateFullRequest => {
                    return (request as IceTradesPostAllocateFullRequest)[0] !== undefined;
                };

                if (isOnlySingleDestinationAllocated(request)) {
                    postIceTradesAllocateFull(request)
                        .unwrap()
                        .then((fulfilled) => {
                            refetch();
                            console.log(fulfilled);
                            dispatch(setSelectedIceTrades([]));
                        })
                        .catch((rejected) => console.error(rejected));
                } else {
                    postIceTradesAllocateSplit(request)
                        .unwrap()
                        .then((fulfilled) => {
                            refetch();
                            console.log(`ICE Trade Split Allocation fulfilled: ${fulfilled}`);
                            dispatch(setSelectedIceTrades([]));
                        })
                        .catch((rejected: IceTradesPostResponse) => {
                            console.log(`ICE Trade Split Allocation rejected: ${JSON.stringify(rejected)}`);
                            const actionMessages = mapIceTradesAllocationApiErrorToActionMessages(rejected);
                            actionMessages.forEach((actionMessage: ActionMessage) =>
                                dispatch(setActionMessage(actionMessage))
                            );
                        });
                }
            });
        }
    };

    useEffect(() => {
        setTotalThermsSelected(calculateTotalSelectedTherms(selectedIceTrades));
    }, [selectedIceTrades]);

    useEffect(() => {
        const remainingTherms = totalThermsSelected - splitAllocationThermsAllocated;
        setAllocationRemainingTherms(remainingTherms);

        let isSplitAllocationFieldPopulated = false;
        for (let i = 0; i < splitAllocationDestinationThermCounts.length; i += 1) {
            if (splitAllocationDestinationThermCounts[i].thermsAllocated !== 0) {
                isSplitAllocationFieldPopulated = true;
            }
        }
        if (remainingTherms !== 0) {
            setAllocationRemainingThermsError(isSplitAllocationFieldPopulated);
        } else {
            setAllocationRemainingThermsError(false);
        }
    }, [totalThermsSelected, splitAllocationThermsAllocated]);

    useEffect(() => {
        let combinedThermsAllocated = 0;
        for (let i = 0; i < splitAllocationDestinationThermCounts.length; i += 1) {
            combinedThermsAllocated += splitAllocationDestinationThermCounts[i].thermsAllocated;
        }
        setSplitAllocationThermsAllocated(combinedThermsAllocated);
    }, [splitAllocationDestinationThermCounts]);

    // this runs whenever we select a first trade or unselect a last trade
    useEffect(() => {
        // put selected therms here
        setTotalThermsSelected(calculateTotalSelectedTherms(selectedIceTrades));
        setSplitAllocationDestinationThermCounts(
            TradeAllocationDestinationsTextList?.map(
                (iceTradesAllocationDestination: TradeAllocationDestinationText) => {
                    return { ...iceTradesAllocationDestination, thermsAllocated: 0, isTooManyTherms: false };
                }
            )
        );
    }, [!selectedIceTrades.length]);

    if (!selectedIceTrades.length) {
        return <></>;
    }

    const readAllocatedTherms = (therms: number, destination: string) => {
        useEffect(() => {
            const updatedSplitAllocationDestinationThermCounts = [...splitAllocationDestinationThermCounts];
            for (let i = 0; i < updatedSplitAllocationDestinationThermCounts.length; i += 1) {
                if (updatedSplitAllocationDestinationThermCounts[i].requestText === destination) {
                    updatedSplitAllocationDestinationThermCounts[i].thermsAllocated = therms;
                }
            }
            let combinedThermsAllocated = 0;
            for (let i = 0; i < updatedSplitAllocationDestinationThermCounts.length; i += 1) {
                combinedThermsAllocated += updatedSplitAllocationDestinationThermCounts[i].thermsAllocated;
            }
            const currentRemainingTherms = totalThermsSelected - combinedThermsAllocated;
            if (currentRemainingTherms < 0) {
                for (let i = 0; i < updatedSplitAllocationDestinationThermCounts.length; i += 1) {
                    if (updatedSplitAllocationDestinationThermCounts[i].requestText === destination) {
                        updatedSplitAllocationDestinationThermCounts[i].isTooManyTherms = true;
                    }
                }
            } else {
                for (let i = 0; i < updatedSplitAllocationDestinationThermCounts.length; i += 1) {
                    updatedSplitAllocationDestinationThermCounts[i].isTooManyTherms = false;
                }
            }
            setSplitAllocationDestinationThermCounts(updatedSplitAllocationDestinationThermCounts);
        }, [therms]);
    };

    const splitAllocationDestinationElements: JSX.Element[] = [];
    splitAllocationDestinationThermCounts?.forEach((iceTradesAllocationDestinationWithTherms) => {
        const { displayText, requestText } = iceTradesAllocationDestinationWithTherms;
        const destinationElementUniqueId = `split-allocation-destination-${requestText.toLowerCase()}`;

        let isTooManyThermsFlag = false;
        for (let i = 0; i < splitAllocationDestinationThermCounts.length; i += 1) {
            if (
                splitAllocationDestinationThermCounts[i].requestText === requestText &&
                splitAllocationDestinationThermCounts[i].isTooManyTherms &&
                splitAllocationDestinationThermCounts[i].thermsAllocated !== 0
            ) {
                isTooManyThermsFlag = true;
            }
        }

        // Generate the relevant jsx for each allocation destination
        const splitAllocationDestinationItem = (
            <AllocationDestinationItem
                id={destinationElementUniqueId}
                key={destinationElementUniqueId}
                requestText={requestText}
                displayText={displayText}
                remainingTherms={allocationRemainingTherms}
                externalErrorFlag={isTooManyThermsFlag}
                onChange={readAllocatedTherms}
            />
        );
        splitAllocationDestinationElements.push(splitAllocationDestinationItem);
    });

    return (
        <>
            {selectedIceTrades.length > 0 && (
                <IceTradesAllocationPanelWrapper data-testid="ice-trades-allocation-panel">
                    <IceTradesAllocationPanelContainer>
                        <IceTradesTotalThermsWrapper data-testid="total-therms-field">
                            <span>{Number(totalThermsSelected).toLocaleString('en-US')}</span>
                        </IceTradesTotalThermsWrapper>
                        <AllocationDestinationElementsContainer>
                            {splitAllocationDestinationElements}
                        </AllocationDestinationElementsContainer>
                        <Paragraph id="split-allocation-remaining-amount">
                            Remaining:
                            <span>{Number(allocationRemainingTherms.toFixed(0)).toLocaleString('en-US')}</span>
                        </Paragraph>
                        <ErrorMessageContainer>
                            {allocationRemainingThermsError && (
                                <ValidationMessage
                                    key="iceTradesAllocationPanelValidationVolume"
                                    id="ice-trades-allocation-panel-error-message"
                                    message="Please allocate remaining volume to zero"
                                    messageType="error"
                                    iconName="warning"
                                />
                            )}
                            {traderIdError && (
                                <ValidationMessage
                                    key="iceTradesAllocationPanelValidationTraderId"
                                    id="ice-trades-allocation-panel-error-message"
                                    message="Please enter a valid trader ID"
                                    messageType="error"
                                    iconName="warning"
                                />
                            )}
                        </ErrorMessageContainer>
                        <ConfirmButtonWrapper>
                            <Button
                                type="button"
                                label={isUpdatingSplit || isUpdatingFull ? 'Loading...' : 'Confirm Allocation'}
                                disabled={
                                    isUpdatingFull ||
                                    isUpdatingSplit ||
                                    traderIdError ||
                                    allocationRemainingThermsError ||
                                    allocationRemainingTherms > 0
                                }
                                size="md"
                                onClick={() => onConfirmAllocateSplitClick(traderIdError)}
                            />
                        </ConfirmButtonWrapper>
                    </IceTradesAllocationPanelContainer>
                </IceTradesAllocationPanelWrapper>
            )}
            ;
        </>
    );
};

const calculateTotalSelectedTherms = (selectedIceTrades: IceTrade[]): number => {
    let totalSelectedTherms = 0;
    selectedIceTrades.forEach((iceTrade: IceTrade) => {
        totalSelectedTherms += Number(iceTrade.volumeTherms);
    });
    return totalSelectedTherms;
};

export { IceTradesAllocationPanel };

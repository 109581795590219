import styled from 'styled-components/macro';

const IceTradesAllocationSelectionPanelContainer = styled.div``;

const IceTradesSelectionPanelHeaderContainer = styled.div`
    padding: 12px 12px 12px 12px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;

    & #drawer-trader-id-field {
        display: flex;
        height: 60px;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 12px;
        border-bottom: solid 1px #b9b9b9;
        padding: 0px;
        & span {
            display: none;
        }
    }
    & #ice-trades-select-all-checkbox {
        margin-top: 24px;
        width: fit-content;
        & #ice-trades-select-all-checkbox_check_box_label {
            font-size: 10px;
        }
    }
`;

const IceTradesElementsContainer = styled.div`
    padding: 0px 12px 12px 12px;

    & p {
        color: #${({ theme }) => theme.colors.supportiveText};
        letter-spacing: 0.8px;
        font-weight: 600;
        margin: 0px 0px 8px 0px;
        & :not(:first-of-type) {
            margin: 16px 0px 8px 0px;
        }
    }
    & #ice-trades-select-all-checkbox {
        width: fit-content;
        & label {
            font-size: 10px;
        }
    }
`;

const RefreshSpanContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & p {
        color: #${({ theme }) => theme.colors.supportiveText};
        letter-spacing: 0.8px;
        font-weight: 600;
        margin: 6px 0px 5px 0px;
    }
`;

export {
    IceTradesAllocationSelectionPanelContainer,
    IceTradesElementsContainer,
    RefreshSpanContainer,
    IceTradesSelectionPanelHeaderContainer
};

// import { DateTime as LuxonDateTime, Interval } from 'luxon';

import { DateTime as LuxonDateTime, Interval } from 'luxon';

const HALF_HOUR_MS = 30 * 60 * 1000;

export default class DateTime {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static isDateTime = (item: any): boolean => {
        // @ts-ignore -- isDateTime is not in the type def.
        return item && item.dateTime && LuxonDateTime.isDateTime(item.dateTime);
    };

    public static isValidIsoDateFormat = (stringToCheck: string): boolean => {
        return LuxonDateTime.fromFormat(stringToCheck, 'yyyy-MM-dd').isValid;
    };

    public static isValidIsoDateTimeFormat = (stringToCheck: string): boolean => {
        return LuxonDateTime.fromFormat(stringToCheck, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").isValid;
    };

    public static getLastSundayOfMonth = (dateTime: LuxonDateTime): LuxonDateTime =>
        dateTime.endOf('month').plus({ days: 1 }).set({ weekday: 0 });

    // @ts-ignore
    private readonly dateTime: LuxonDateTime;

    constructor(rawDate?: string | number | DateTime | Date | LuxonDateTime) {
        const utcZone = { zone: 'utc' };

        if (!rawDate) {
            this.dateTime = LuxonDateTime.utc();
        } else if (typeof rawDate === 'string') {
            this.dateTime = LuxonDateTime.fromISO(rawDate, utcZone);
        } else if (typeof rawDate === 'number') {
            this.dateTime = LuxonDateTime.fromMillis(rawDate, utcZone);
        } else if (rawDate instanceof DateTime) {
            this.dateTime = rawDate.dateTime;
        } else if (rawDate instanceof Date) {
            this.dateTime = LuxonDateTime.fromJSDate(rawDate);
        } else if (rawDate instanceof LuxonDateTime) {
            this.dateTime = rawDate;
        }
        // @ts-ignore
        if (!this.dateTime.isValid) {
            throw Error(`DateTime constructor called with invalid date format ${JSON.stringify(rawDate)}`);
        }
    }

    public toIsoString = (): string => this.dateTime.toISO();

    public toCognitoSrpFormat = (): string => this.dateTime.toFormat("ccc LLL d HH:mm:ss 'UTC' yyyy");

    public toMillisecondsPastEpoch = (): number => this.dateTime.toMillis();

    public toIsoDateFormat = (): string => this.dateTime.toFormat('yyyy-MM-dd');

    public toSentinelFormat = (): string => this.dateTime.toFormat('yyyy-MM-dd HH:mm:ss');

    public toSentinelCSVFormat = (): string => this.dateTime.toFormat('yyyyMMddHHmm');

    // Need to set sec/ms to zero, or else suppressSeconds and suppressMilliseconds will not work
    public toGenstarFormat = (): string =>
        this.dateTime.set({ second: 0, millisecond: 0 }).toISO({ suppressSeconds: true, suppressMilliseconds: true });

    public toIsoStringWithZeroSecondsAndWithoutMs = (): string =>
        this.dateTime.set({ second: 0, millisecond: 0 }).toISO({ suppressMilliseconds: true });

    public toSimpleDateTimeFormat = (): string => this.dateTime.toFormat('dd-MM-yyyy HH:mm:ss');

    public toSimpleWordDateFormat = (): string => this.dateTime.toFormat('dd MMM yy');

    public toHourMinuteTimeFormat = (): string => this.dateTime.toFormat('HH:mm');

    public getLocalDateTime = (): LuxonDateTime => {
        return this.dateTime.toLocal();
    };

    public getEfaDateTime = (): DateTime => {
        const efaDate = this.dateTime
            .setZone('Europe/London')
            .minus({ days: 1 })
            .set({ hour: 23, minute: 0, second: 0, millisecond: 0 })
            .toUTC();

        return new DateTime(efaDate.toISO());
    };

    public setDay = (days: number): DateTime => {
        return new DateTime(this.dateTime.set({ day: days }).toISO());
    };

    public getDay = (): number => {
        return this.dateTime.get('day');
    };

    public getMonth = (): number => {
        return this.dateTime.get('month');
    };

    public getYear = (): number => {
        return this.dateTime.get('year');
    };

    public addMinutes = (minutes: number): DateTime => {
        const newDate = this.dateTime.plus({ minutes });

        return new DateTime(newDate.toISO());
    };

    public addHours = (hours: number): DateTime => {
        const newDate = this.dateTime.plus({ hours });

        return new DateTime(newDate.toISO());
    };

    public getMinutes = (): number => {
        return this.dateTime.get('minute');
    };

    public setHours = (hours: number): DateTime => {
        return new DateTime(this.dateTime.set({ hour: hours }).toISO());
    };

    public getHours = (): number => {
        return this.dateTime.get('hour');
    };

    public setMinutes = (minutes: number): DateTime => {
        return new DateTime(this.dateTime.set({ minute: minutes }).toISO());
    };

    public setSeconds = (seconds: number): DateTime => {
        return new DateTime(this.dateTime.set({ second: seconds }).toISO());
    };

    public getSeconds = (): number => {
        return this.dateTime.get('second');
    };

    public setMilliseconds = (milliseconds: number): DateTime => {
        return new DateTime(this.dateTime.set({ millisecond: milliseconds }).toISO());
    };

    public getMilliseconds = (): number => {
        return this.dateTime.get('millisecond');
    };

    /**
     * The following diff functions:
     * Will return a negative number if the otherDateTime is ahead, so if the otherDateTime
     * was 3 hours/minutes/seconds ahead the function would return -3.
     * Will return a positive number if the otherDateTime is behind, so if this._dateTime
     * is 3 hours/minutes/seconds ahead of otherDateTime this function would return 3
     *
     * @param otherDateTime DateTime Object
     * @returns {number} number of hours/minutes/seconds difference
     */

    public diffMinutes = (otherDateTime: DateTime): number => {
        const diff = this.dateTime.diff(otherDateTime.dateTime, 'minutes').toObject();
        if (!diff.minutes) {
            diff.minutes = 0;
        }
        return diff.minutes;
    };

    public diffSeconds = (otherDateTime: DateTime): number => {
        const diff = this.dateTime.diff(otherDateTime.dateTime, 'seconds').toObject();
        if (!diff.seconds) {
            diff.seconds = 0;
        }
        return diff.seconds;
    };

    public diffHours = (otherDateTime: DateTime): number => {
        const diff = this.dateTime.diff(otherDateTime.dateTime, 'hours').toObject();
        if (!diff.hours) {
            diff.hours = 0;
        }
        return diff.hours;
    };

    public addDays = (days: number): DateTime => {
        const newDate = this.dateTime.plus({ days });

        return new DateTime(newDate.toISO());
    };

    // Diffs days using start of day instead of time between date times
    public diffDaysWhole = (otherDateTime: DateTime): number => {
        const startOfDay = this.dateTime.startOf('day');
        const otherStartOfday = otherDateTime.dateTime.startOf('day');

        return startOfDay.diff(otherStartOfday, 'days').days;
    };

    public diffDays = (otherDateTime: DateTime): number => {
        return this.dateTime.diff(otherDateTime.dateTime, 'days').days;
    };

    public diffYears = (otherDateTime: DateTime): number => {
        return this.dateTime.diff(otherDateTime.dateTime, 'year').years;
    };

    /**
     * Check for inclusion of the DateTime between a given start and end time.
     * This is inclusive of the start time, but exclusive of the end time
     *
     * @param startTime start DateTime of interval to check
     * @param endTime end DateTime of interval to check
     * @param timeToCheck DateTime to check interval contains
     */
    public isBetween = (startTime: DateTime, endTime: DateTime): boolean => {
        const luxonStartTime = LuxonDateTime.fromISO(startTime.toIsoString());
        const luxonEndTime = LuxonDateTime.fromISO(endTime.toIsoString());
        const interval = Interval.fromDateTimes(luxonStartTime, luxonEndTime);
        return interval.contains(this.dateTime);
    };

    public isSameOrBefore = (otherDateTime: DateTime): boolean =>
        this.dateTime.toMillis() - otherDateTime.toMillisecondsPastEpoch() <= 0;

    public isSame = (otherDateTime: DateTime): boolean =>
        this.dateTime.toMillis() - otherDateTime.toMillisecondsPastEpoch() === 0;

    public isSameOrAfter = (otherDateTime: DateTime): boolean =>
        this.dateTime.toMillis() - otherDateTime.toMillisecondsPastEpoch() >= 0;

    public isBefore = (otherDateTime: DateTime): boolean =>
        this.dateTime.toMillis() - otherDateTime.toMillisecondsPastEpoch() < 0;

    public isAfter = (otherDateTime: DateTime): boolean =>
        this.dateTime.toMillis() - otherDateTime.toMillisecondsPastEpoch() > 0;

    public isShortDay = (): boolean => {
        const isMarch = this.dateTime.month === 3;
        const lastSunday = DateTime.getLastSundayOfMonth(this.dateTime);

        return isMarch && this.dateTime.hasSame(lastSunday, 'day');
    };

    public isLongDay = (): boolean => {
        const isOctober = this.dateTime.month === 10;
        const lastSunday = DateTime.getLastSundayOfMonth(this.dateTime);

        return isOctober && this.dateTime.hasSame(lastSunday, 'day');
    };

    public isOnHalfHourInterval = (): boolean => {
        const minuteCheck: boolean = this.dateTime.get('minute') % 30 === 0;
        const secondCheck: boolean = this.dateTime.get('second') === 0;
        const millisecondCheck: boolean = this.dateTime.get('millisecond') === 0;
        return minuteCheck && secondCheck && millisecondCheck;
    };

    public startOfDay = (): DateTime => {
        return new DateTime(this.dateTime.startOf('day').toISO());
    };

    public startOfHalfHourBlock = (): DateTime => {
        const minutes = this.dateTime.minute;
        if (minutes < 30) {
            return new DateTime(this.dateTime.set({ minute: 0, second: 0, millisecond: 0 }).toISO());
        }

        return new DateTime(this.dateTime.set({ minute: 30, second: 0, millisecond: 0 }).toISO());
    };

    public endOfHalfHourBlock = (): DateTime => {
        const minutes = this.dateTime.minute;

        if (minutes < 30) {
            return new DateTime(this.dateTime.set({ minute: 30, second: 0, millisecond: 0 }).toISO());
        }

        return new DateTime(
            this.dateTime
                .set({
                    hour: this.dateTime.hour + 1,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                })
                .toISO()
        );
    };

    public isDiffMoreThanHalfHour = (date: DateTime): boolean => {
        return Math.abs(date.toMillisecondsPastEpoch() - this.dateTime.toMillis()) > HALF_HOUR_MS;
    };

    public isStartMinuteInFirstHalfHour = (): boolean => this.dateTime.get('minute') <= 29;

    public isEndMinuteInFirstHalfHour = (): boolean =>
        this.dateTime.get('minute') >= 1 && this.dateTime.get('minute') <= 30;

    public isStartMinuteInSecondHalfHour = (): boolean =>
        this.dateTime.get('minute') >= 30 && this.dateTime.get('minute') <= 59;

    public isEndMinuteInSecondHalfHour = (): boolean =>
        this.dateTime.get('minute') === 0 || this.dateTime.get('minute') >= 31;
}

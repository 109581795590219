import React from 'react';
import { Icon, ValidationMessageContainer } from './styles';
import { ValidationMessageType, ValidationMessageProps } from './types';

const handleMessageType = (type: ValidationMessageType) => {
    if (type === undefined) {
        return '#000000';
    }
    switch (type) {
        case 'error':
            return '#e32747';
        case 'warn':
            return '#FE5716';
        case 'message':
            return '#000000';
        case 'success':
            return '#005500';
        default:
            return '#e32747';
    }
};

const ValidationMessage: React.FC<ValidationMessageProps> = (props): React.ReactElement => {
    const { id, message, messageType, iconName } = props;
    return (
        <ValidationMessageContainer id={id} messageType={messageType} handleMessageType={handleMessageType}>
            {iconName && (
                <Icon id="validationMsgIcon" className="material-icons" iconName={iconName}>
                    {iconName}
                </Icon>
            )}
            {message}
        </ValidationMessageContainer>
    );
};

export { ValidationMessage };

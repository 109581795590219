import React from 'react';

import { Paragraph, Tab, Tabs } from '@edfenergy/shift-desk-wallace';
import { TradesPanelProps } from './types';

import { TradesPanelWrapper } from './styles';
import { InternalTransfersPanel } from '../../../features/internalTransfers/components/InternalTransfersPanel';
import { IceTradesAllocationSelectionPanel } from '../../../features/iceTrades/components/IceTradesAllocationSelectionPanel';
import { OtcTradesPanel } from '../../../features/otcTrades/components/OtcTradesPanel';

const TradesPanel: React.FC<TradesPanelProps> = () => {
    return (
        <TradesPanelWrapper>
            <Paragraph size="lg" id="ice-trades-panel-title" data-testid="ice-trades_panel_header">
                Trade Allocation
            </Paragraph>
            <Tabs id="ice-trades-panel" tabSize="small" activeTabIndex="ICE allocation">
                <Tab title="Internal Transfer">
                    <InternalTransfersPanel />
                </Tab>
                <Tab title="ICE allocation">
                    <IceTradesAllocationSelectionPanel />
                </Tab>
                <Tab title="OTC allocation">
                    <OtcTradesPanel />
                </Tab>
            </Tabs>
        </TradesPanelWrapper>
    );
};

export { TradesPanel };

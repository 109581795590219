export enum TRADE_ALLOCATION_DESTINATION {
    PORTFOLIO = 'PORTFOLIO',
    WBB = 'WBB',
    HILLTOP = 'HILLTOP',
    HOLE_HOUSE = 'HOLE_HOUSE',
    GAS_PEAKERS = 'GAS_PEAKERS'
}

export enum TRADE_ALLOCATION_DESTINATION_DISPLAY_TEXT {
    PORTFOLIO = 'Portfolio',
    WBB = 'West Burton B',
    HILLTOP = 'Hilltop',
    HOLE_HOUSE = 'Hole House',
    GAS_PEAKERS = 'Gas Peakers'
}

export type TradeAllocationDestinationText = {
    requestText: TRADE_ALLOCATION_DESTINATION;
    displayText: TRADE_ALLOCATION_DESTINATION_DISPLAY_TEXT;
};

export const TradeAllocationDestinationsTextList: TradeAllocationDestinationText[] = [
    {
        requestText: TRADE_ALLOCATION_DESTINATION.PORTFOLIO,
        displayText: TRADE_ALLOCATION_DESTINATION_DISPLAY_TEXT.PORTFOLIO
    },
    {
        requestText: TRADE_ALLOCATION_DESTINATION.WBB,
        displayText: TRADE_ALLOCATION_DESTINATION_DISPLAY_TEXT.WBB
    },
    {
        requestText: TRADE_ALLOCATION_DESTINATION.HILLTOP,
        displayText: TRADE_ALLOCATION_DESTINATION_DISPLAY_TEXT.HILLTOP
    },
    {
        requestText: TRADE_ALLOCATION_DESTINATION.HOLE_HOUSE,
        displayText: TRADE_ALLOCATION_DESTINATION_DISPLAY_TEXT.HOLE_HOUSE
    },
    {
        requestText: TRADE_ALLOCATION_DESTINATION.GAS_PEAKERS,
        displayText: TRADE_ALLOCATION_DESTINATION_DISPLAY_TEXT.GAS_PEAKERS
    }
];

import styled from 'styled-components/macro';

const GasNopContainerWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    min-width: 640px;
    background-color: #e5eff8;

    // max-height computed using "veiwport height - header height"
    // This was to prevent the Ice Trades Panel from causing the entire body to scroll
    // instead of just the panel.
    max-height: calc(100vh - 52px);
`;

const GasNopPanel = styled.div`
    position: relative;
    flex: auto;
    padding: 12px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export { GasNopContainerWrapper, GasNopPanel };

import React, { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';

import { Button, CheckBox, Paragraph } from '@edfenergy/shift-desk-wallace';
import { NotVoid, uniqBy } from 'lodash';
import { DateTime } from '../../../../common/dateTime';

import { selectSelectedIceTrades, setSelectedIceTrades } from '../../iceTradesSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useGetIceTradesUnallocatedQuery } from '../../../../services/iceTradesApi';

import { IceTradeItem } from '../IceTradeItem';
import { IceTradesAllocationPanel } from '../IceTradesAllocationPanel';
import { IceTrade } from '../../types';

import {
    RefreshSpanContainer,
    IceTradesElementsContainer,
    IceTradesSelectionPanelHeaderContainer,
    IceTradesAllocationSelectionPanelContainer
} from './styles';
import { TraderIdField } from '../../../user/components/TraderIdField';
import { setTraderId, setTraderIdError } from '../../../user/userSlice';
import { IceTradesAllocationSelectionPanelProps } from './types';
import { TRADE_ALLOCATION_STATUS } from '../../../../common/types';
import { DateCheckboxContainer } from '../IceTradesAllocationPanel/styles';

const IceTradesAllocationSelectionPanel: React.FC<IceTradesAllocationSelectionPanelProps> = (ref) => {
    const dispatch = useAppDispatch();
    const selectedIceTrades: IceTrade[] = useAppSelector(selectSelectedIceTrades);

    const { data, error, isLoading, isError, refetch } = useGetIceTradesUnallocatedQuery();
    const lastTimeChecked = data?.lastTimeChecked;
    const iceTrades = data?.iceTrades ?? [];

    const [showErrorMsg, setShowErrorMsg] = useState(false);

    const onSelectAllCheckboxClick = (isAllSelected: boolean) => {
        // In redux set all non pending trades to isAllSelected (true/false)
        if (!isAllSelected) {
            dispatch(setSelectedIceTrades([]));
            return;
        }

        const newSelectedTradesState = iceTrades.filter((iceTrade) => {
            return iceTrade.allocationStatus !== TRADE_ALLOCATION_STATUS.PENDING;
        });

        dispatch(setSelectedIceTrades(newSelectedTradesState));
    };

    const onSelectDateGroupCheckboxClick = (date: string, isDateCheckboxSelected: boolean): void => {
        // If this date group check box is false, remove trades for this date group from the selecte ice trades state
        if (!isDateCheckboxSelected) {
            const newSelectedIceTrades = selectedIceTrades.filter((selectedIceTrade) => {
                return selectedIceTrade.deliveryStartDate !== date;
            });

            dispatch(setSelectedIceTrades(newSelectedIceTrades));
        }

        // If this date group check box is true, add the trades for this date group to the selected ice trades state
        else {
            const newSelectedIceTrades = iceTrades.filter((iceTrade) => {
                return (
                    iceTrade.allocationStatus !== TRADE_ALLOCATION_STATUS.PENDING && iceTrade.deliveryStartDate === date
                );
            });

            const selectedTradesState = uniqBy([...selectedIceTrades, ...newSelectedIceTrades], 'dealId');

            dispatch(setSelectedIceTrades(selectedTradesState));
        }
    };

    const onIceTradeItemClick = (id: number, isTradeSelected: boolean) => {
        const previouslySelectedIds = selectedIceTrades.map((iceTrade) => iceTrade.dealId);
        const newSelectedTradesState = iceTrades.filter((iceTrade) => {
            if (iceTrade.dealId === id) {
                return isTradeSelected;
            }
            return previouslySelectedIds.includes(iceTrade.dealId);
        });

        dispatch(setSelectedIceTrades(newSelectedTradesState));
    };

    const isDateGroupCheckboxSelected = (date: string): boolean => {
        // From selected ice trades, filter to get list for just ones in date group
        const selectedIceTradesForDateGroup: IceTrade[] = selectedIceTrades.filter(
            (selectedIceTrade) => selectedIceTrade.deliveryStartDate === date && selectedIceTrade.allocationStatus
        );

        // From all unallocated ice trades, filter to get list for just ones in date group
        const nonPendingTradesForDateGroup: IceTrade[] = iceTrades
            ? iceTrades.filter(
                  (iceTrade) =>
                      iceTrade.deliveryStartDate === date &&
                      iceTrade.allocationStatus !== TRADE_ALLOCATION_STATUS.PENDING
              )
            : [];

        if (nonPendingTradesForDateGroup.length === 0) {
            return false;
        }

        // Compare these, if num of trades in both match, check the date group check box
        return selectedIceTradesForDateGroup.length === nonPendingTradesForDateGroup.length;
    };

    const isTradeSelected = (dealId: number): boolean => {
        const previouslySelectedIds = selectedIceTrades.map((iceTrade) => iceTrade.dealId);

        return previouslySelectedIds.includes(dealId);
    };

    const isSelectAllCheckboxSelected = (): boolean => {
        // Get all the unallocated trades that are not pending
        const nonPendingTrades: IceTrade[] = iceTrades?.filter(
            (trade: IceTrade) => trade.allocationStatus !== TRADE_ALLOCATION_STATUS.PENDING
        );

        // Compare selected ice trades with all non pending ice trades, if num in both matches check the select all check box
        // and if all the unallocated ice trades are NOT pending status
        const allPending = iceTrades.every((trade) => trade.allocationStatus === 'PENDING');

        return selectedIceTrades.length === nonPendingTrades?.length && !allPending;
    };

    const isAllTradesForDatePending = (date: string): boolean => {
        const tradesForDate = iceTrades?.filter((iceTrade: IceTrade) => {
            return iceTrade.deliveryStartDate === date;
        });

        if (!tradesForDate) {
            return false;
        }

        return tradesForDate?.every((iceTrade: IceTrade) => {
            return iceTrade.allocationStatus === TRADE_ALLOCATION_STATUS.PENDING;
        });
    };

    const iceTradeDeliveryStartDates: string[] = uniq(
        iceTrades?.map((iceTrade: IceTrade) => iceTrade.deliveryStartDate)
    );

    const iceTradesElements: JSX.Element[] = [];
    if (!iceTradeDeliveryStartDates.length) {
        iceTradesElements.push(
            <Paragraph size="sm" key="no_icetrades_status_text">
                There are currently no unallocated Ice Trades.
            </Paragraph>
        );
    }

    iceTradeDeliveryStartDates.forEach((date: string) => {
        const formattedDate = new DateTime(date).toSimpleWordDateFormat();
        const selectAllForDateTitle = `Select all for ${formattedDate}`;
        const testIdDeliveryDateCheckboxContainer = `delivery_date_checkbox_container_${formattedDate}`;
        const testIdDeliveryDateCheckbox = `delivery_date_checkbox_${formattedDate}`;

        iceTradesElements.push(
            <>
                <DateCheckboxContainer data-testid={testIdDeliveryDateCheckboxContainer}>
                    <CheckBox
                        id={date}
                        data-testid={testIdDeliveryDateCheckbox}
                        key={date}
                        label={selectAllForDateTitle}
                        checked={isDateGroupCheckboxSelected(date)}
                        disabled={isAllTradesForDatePending(date)}
                        onChange={(isDateCheckboxSelected: boolean) =>
                            onSelectDateGroupCheckboxClick(date, isDateCheckboxSelected)
                        }
                    />
                </DateCheckboxContainer>
            </>
        );

        iceTrades?.forEach((iceTrade: IceTrade) => {
            const { dealId } = iceTrade;
            const isIceTradeSelected = isTradeSelected(dealId);

            if (iceTrade.deliveryStartDate === date) {
                const iceTradeItem = (
                    <IceTradeItem
                        key={dealId}
                        id={dealId.toString()}
                        isTradeSelected={isIceTradeSelected}
                        iceTrade={iceTrade}
                        onChange={(id: string, isIceTradeSelected: boolean) =>
                            onIceTradeItemClick(parseInt(id, 10), isIceTradeSelected)
                        }
                    />
                );
                iceTradesElements.push(iceTradeItem);
            }
        });
    });

    const requestStatusElements: JSX.Element[] = [];
    if (isError) {
        requestStatusElements.push(
            <>
                <Paragraph size="sm" key="request_error_status_text">
                    There was an issue retrieving data. Please contact IT Support.
                </Paragraph>
                <Paragraph size="sm" key="request_error_output">
                    {JSON.stringify(error, null, 2)}
                </Paragraph>
            </>
        );
    } else if (isLoading) {
        requestStatusElements.push(
            <Paragraph size="sm" key="loading_status_text">
                loading...
            </Paragraph>
        );
    } else {
        requestStatusElements.push(<div key="ice-trades-elements">{iceTradesElements}</div>);
    }

    const checkTraderIdError = (traderId: string, hasError: boolean) => {
        let error = hasError;
        if (traderId.length < 4) {
            error = true;
        }

        useEffect(() => {
            dispatch(setTraderId(traderId));
        }, [traderId]);

        useEffect(() => {
            dispatch(setTraderIdError(error));
            if (traderId.length !== 0) {
                setShowErrorMsg(error);
            }
        }, [error]);

        return [traderId, hasError];
    };

    return (
        <IceTradesAllocationSelectionPanelContainer>
            <IceTradesSelectionPanelHeaderContainer>
                <TraderIdField
                    id="drawer-trader-id-field"
                    externalErrorFlag={showErrorMsg}
                    onChange={checkTraderIdError}
                />
                <RefreshSpanContainer>
                    <Paragraph size="sm">Last checked: {lastTimeChecked}</Paragraph>
                    <Button
                        label="Refresh Trades"
                        type="button"
                        size="md"
                        onClick={refetch}
                        colorScheme="secondary"
                        iconName="refresh"
                        iconPos="left"
                    />
                </RefreshSpanContainer>
                {iceTradeDeliveryStartDates.length !== 0 && (
                    <CheckBox
                        id="ice-trades-select-all-checkbox"
                        key="select_all_check_box"
                        label="Select all"
                        checked={isSelectAllCheckboxSelected()}
                        disabled={iceTrades.every((trade) => trade.allocationStatus === 'PENDING')}
                        onChange={(isAllSelected: boolean) => onSelectAllCheckboxClick(isAllSelected)}
                    />
                )}
            </IceTradesSelectionPanelHeaderContainer>
            <IceTradesElementsContainer>{requestStatusElements}</IceTradesElementsContainer>
            {selectedIceTrades.length > 0 && <IceTradesAllocationPanel />}
        </IceTradesAllocationSelectionPanelContainer>
    );
};

export { IceTradesAllocationSelectionPanel };

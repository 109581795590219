import {
    Paragraph,
    DatePicker,
    TextInput,
    RadioButtonGroup,
    Label,
    CheckBox,
    Button,
    DatePickerDateTime
} from '@edfenergy/shift-desk-wallace';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ValidationMessage } from '../../../../common/components/ValidationMessage';
import { DateTime } from '../../../../common/dateTime';
import { setTradesPanelOpen } from '../../../../common/TradesPanelSlice';
import {
    BUY_SELL,
    TradeAllocationDestinationsTextList,
    TradeAllocationDestinationText,
    TRADE_ALLOCATION_DESTINATION
} from '../../../../common/types';
import { capitaliseFirstLetter } from '../../../../common/utilities';
import { usePostOtcTradesMutation } from '../../../../services/otcTradesApi';
import { useGetZapiResponseQuery } from '../../../../services/zapiResponseApi';
import { selectWebSocketReconnect, setWebSocketReconnect } from '../../../../services/zapiResponseSlice';
import { setActionMessage } from '../../../actionMessages/actionMessagesSlice';
import { mapOtcTradesApiErrorToActionMessages } from '../../../actionMessages/maps';
import { ActionMessage } from '../../../actionMessages/types';
import { AllocationDestinationItem } from '../../../iceTrades/components/AllocationDestinationItem';
import { TraderIdField } from '../../../user/components/TraderIdField';
import { selectTraderId, selectTraderIdError, setTraderId, setTraderIdError } from '../../../user/userSlice';
import {
    OtcTradesBuySellContainer,
    OtcTradesConfirmButtonContainer,
    OtcTradesConfirmButtonWrapper,
    OtcTradesDestinationElementsContainer,
    OtcTradesGasDayFieldContainer,
    OtcTradesPanelContainer,
    OtcTradesPanelHeaderContainer,
    OtcTradesSegmentSelectionMenuWrapper,
    OtcTradesTimeSelectionContainer,
    OtcTradesTimeSelectionDateDisplay,
    OtcTradesVolumeEntryContainer
} from './styles';
import { OtcTradesPanelProps } from './types';
import { OTC_COUNTERPARTY, OTC_SEGMENT } from '../../types/OtcTrades';
import { selectOtcTradesForm, setOtcTradesFormState } from '../../otcTradesSlice';

const OtcTradesPanel: React.FC<OtcTradesPanelProps> = () => {
    const dispatch = useAppDispatch();
    const traderId = useAppSelector(selectTraderId);
    const traderIdError = useAppSelector(selectTraderIdError);
    const webSocketReconnect = useAppSelector(selectWebSocketReconnect);

    const otcTradesFormState = useAppSelector(selectOtcTradesForm);

    const {
        selectedDate,
        selectedProduct,
        buySellValue,
        brokerage,
        allocatedPrice,
        totalAllocatedVolume,
        destinationThermCounts
    } = otcTradesFormState;

    const { data, error, isError, isSuccess, isLoading } = useGetZapiResponseQuery(webSocketReconnect);

    const [postOtcTrades, { isLoading: isUpdatingOtc }] = usePostOtcTradesMutation();

    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [otcTradesDeliveryDates, setOtcTradesDeliveryDates] = useState([new DateTime(), new DateTime()]);
    const [formPreviouslySubmitted, setFormPreviouslySubmitted] = useState(false);

    const [otcTradesTotalThermsAvailable, setOtcTradesTotalThermsAvailable] = useState(
        otcTradesFormState.totalAllocatedVolume
    );

    let combinedThermsAllocated = 0;
    for (let i = 0; i < destinationThermCounts.length; i += 1) {
        combinedThermsAllocated += destinationThermCounts[i].thermsAllocated;
    }
    const [otcTradesThermsAllocated, setOtcTradesThermsAllocated] = useState(combinedThermsAllocated);

    const [otcTradesRemainingTherms, setOtcTradesRemainingTherms] = useState(otcTradesTotalThermsAvailable);
    const [otcTradesRemainingThermsError, setOtcTradesRemainingThermsError] = useState(false);

    const [otcTradesVolumeError, setOtcTradesVolumeError] = useState(false);
    const [otcTradesPriceError, setOtcTradesPriceError] = useState(false);
    const [otcTradesPriceWarning, setOtcTradesPriceWarning] = useState(false);

    useEffect(() => {
        if (selectedDate === '') {
            dispatch(
                setOtcTradesFormState({
                    ...otcTradesFormState,
                    selectedDate: new DateTime().toIsoDateFormat()
                })
            );
        }
        if (destinationThermCounts.length === 0) {
            dispatch(
                setOtcTradesFormState({
                    ...otcTradesFormState,
                    destinationThermCounts: TradeAllocationDestinationsTextList?.map(
                        (iceTradesAllocationDestination: TradeAllocationDestinationText) => {
                            return { ...iceTradesAllocationDestination, thermsAllocated: 0, isTooManyTherms: false };
                        }
                    )
                })
            );
        }
    }, []);

    useEffect(() => {
        setOtcTradesVolumeError(totalAllocatedVolume <= 0);
    }, [totalAllocatedVolume]);

    useEffect(() => {
        setOtcTradesPriceError(allocatedPrice <= 0);
        setOtcTradesPriceWarning(allocatedPrice >= 10);
    }, [allocatedPrice]);

    useEffect(() => {
        setOtcTradesTotalThermsAvailable(totalAllocatedVolume);
    }, [totalAllocatedVolume]);

    useEffect(() => {
        let combinedThermsAllocated = 0;
        for (let i = 0; i < destinationThermCounts.length; i += 1) {
            combinedThermsAllocated += destinationThermCounts[i].thermsAllocated;
        }
        setOtcTradesThermsAllocated(combinedThermsAllocated);
    }, [destinationThermCounts]);

    useEffect(() => {
        const remainingTherms = otcTradesTotalThermsAvailable - otcTradesThermsAllocated;
        setOtcTradesRemainingTherms(remainingTherms);

        let isOtcTradesDestinationFieldPopulated = false;
        for (let i = 0; i < destinationThermCounts.length; i += 1) {
            if (destinationThermCounts[i].thermsAllocated !== 0) {
                isOtcTradesDestinationFieldPopulated = true;
            }
        }
        if (remainingTherms !== 0) {
            setOtcTradesRemainingThermsError(isOtcTradesDestinationFieldPopulated);
        } else {
            setOtcTradesRemainingThermsError(false);
        }
    }, [otcTradesTotalThermsAvailable, otcTradesThermsAllocated]);

    useEffect(() => {
        setOtcTradesVolumeError(totalAllocatedVolume <= 0);
    }, [otcTradesVolumeError]);

    useEffect(() => {
        setOtcTradesPriceError(allocatedPrice <= 0);
    }, [otcTradesPriceError]);

    useEffect(() => {
        setOtcTradesPriceWarning(allocatedPrice >= 10);
    }, [otcTradesPriceWarning]);

    useEffect(() => {
        if (selectedProduct === 'WD') {
            const startDate = new DateTime(selectedDate);
            const endDate = new DateTime(selectedDate).setDay(new DateTime(selectedDate).getDay() + 1);
            setOtcTradesDeliveryDates([startDate, endDate]);
        } else if (selectedProduct === 'DA') {
            const startDate = new DateTime(selectedDate).setDay(new DateTime(selectedDate).getDay() + 1);
            const endDate = new DateTime(selectedDate).setDay(new DateTime(selectedDate).getDay() + 2);
            setOtcTradesDeliveryDates([startDate, endDate]);
        }
    }, [selectedDate, selectedProduct]);

    const onCloseTradesPanel = () => {
        dispatch(setTradesPanelOpen(false));
    };

    const checkTraderIdError = (traderId: string, hasError: boolean) => {
        let error = hasError;
        if (traderId.length < 4) {
            error = true;
        }

        useEffect(() => {
            dispatch(setTraderId(traderId));
        }, [traderId]);

        useEffect(() => {
            dispatch(setTraderIdError(error));
            if (traderId.length !== 0) {
                setShowErrorMsg(error);
            }
        }, [error]);

        return [traderId, hasError];
    };

    const onConfirmAllocateOtcTradesClick = (traderIdError: boolean) => {
        if (data?.isWebSocketConnected === false) {
            dispatch(setWebSocketReconnect(!webSocketReconnect));
        }

        const volumeDestinationArray: { volumeTherms: number; allocatedTo: TRADE_ALLOCATION_DESTINATION }[] = [];
        for (let i = 0; i < destinationThermCounts.length; i += 1) {
            if (destinationThermCounts[i].thermsAllocated > 0) {
                const volumeDestinationItem = {
                    volumeTherms: destinationThermCounts[i].thermsAllocated,
                    allocatedTo: destinationThermCounts[i].requestText
                };
                volumeDestinationArray.push(volumeDestinationItem);
            }
        }

        const onlyPortfolioAllocated =
            volumeDestinationArray.length === 1 &&
            volumeDestinationArray[0].allocatedTo === TRADE_ALLOCATION_DESTINATION.PORTFOLIO;

        const isSplitWithPortfolio = volumeDestinationArray.some(
            (volumeDestinationObject) =>
                volumeDestinationObject.allocatedTo === TRADE_ALLOCATION_DESTINATION.PORTFOLIO &&
                volumeDestinationArray.length > 1
        );

        const isSplitWithWbb = volumeDestinationArray.some(
            (volumeDestinationObject) =>
                volumeDestinationObject.allocatedTo === TRADE_ALLOCATION_DESTINATION.WBB &&
                volumeDestinationArray.length > 1
        );

        if (!traderIdError && totalAllocatedVolume !== 0 && allocatedPrice !== 0 && otcTradesRemainingTherms === 0) {
            // The initial booking trade is sent first
            postOtcTrades({
                traderId,
                tradeDate: new DateTime(selectedDate).toIsoDateFormat(),
                deliveryStartDate: otcTradesDeliveryDates[0].toIsoDateFormat(),
                deliveryEndDate: otcTradesDeliveryDates[1].toIsoDateFormat(),
                product: selectedProduct,
                buySell: capitaliseFirstLetter(
                    (buySellValue === 0 ? BUY_SELL.BUY : BUY_SELL.SELL).toLowerCase()
                ) as BUY_SELL,
                segment: OTC_SEGMENT.EDF,
                counterparty: OTC_COUNTERPARTY.EDFT,
                brokerage,
                priceGBP: allocatedPrice,
                volumeTherms: totalAllocatedVolume,
                allocationControl: onlyPortfolioAllocated ? 'A' : 'C',
                destination: undefined,
                isSplitWithWbb
            })
                .unwrap()
                .then((fulfilled) => {
                    // Subsequent trades are sent for each of the selected destinations only if the initial booking trade was sent successfully
                    for (let i = 0; i < volumeDestinationArray.length; i += 1) {
                        postOtcTrades({
                            traderId,
                            tradeDate: new DateTime(selectedDate).toIsoDateFormat(),
                            deliveryStartDate: otcTradesDeliveryDates[0].toIsoDateFormat(),
                            deliveryEndDate: otcTradesDeliveryDates[1].toIsoDateFormat(),
                            product: selectedProduct,
                            buySell: capitaliseFirstLetter(
                                (buySellValue === 0 ? BUY_SELL.BUY : BUY_SELL.SELL).toLowerCase()
                            ) as BUY_SELL,
                            segment: OTC_SEGMENT.EDF,
                            counterparty: OTC_COUNTERPARTY.EDFT,
                            brokerage,
                            priceGBP: allocatedPrice,
                            volumeTherms: volumeDestinationArray[i].volumeTherms,
                            allocationControl: onlyPortfolioAllocated ? 'A' : 'C',
                            destination: volumeDestinationArray[i].allocatedTo,
                            isSplitWithWbb,
                            isSplitWithPortfolio:
                                isSplitWithPortfolio &&
                                volumeDestinationArray[i].allocatedTo === TRADE_ALLOCATION_DESTINATION.PORTFOLIO
                        })
                            .unwrap()
                            .then((fulfilled) => {
                                console.log(fulfilled);
                            })
                            .catch((rejected) => {
                                console.error(`Otc Trade rejected: ${rejected}`);
                                const actionMessages = mapOtcTradesApiErrorToActionMessages(rejected);
                                actionMessages.forEach((actionMessage: ActionMessage) =>
                                    dispatch(setActionMessage(actionMessage))
                                );
                            });
                    }
                    dispatch(
                        setOtcTradesFormState({
                            ...otcTradesFormState,
                            allocatedPrice: 0,
                            totalAllocatedVolume: 0,
                            destinationThermCounts: TradeAllocationDestinationsTextList?.map(
                                (iceTradesAllocationDestination: TradeAllocationDestinationText) => {
                                    return {
                                        ...iceTradesAllocationDestination,
                                        thermsAllocated: 0,
                                        isTooManyTherms: false
                                    };
                                }
                            )
                        })
                    );
                    setOtcTradesTotalThermsAvailable(0);
                    setFormPreviouslySubmitted(!formPreviouslySubmitted);
                    console.log(fulfilled);
                })
                .catch((rejected) => {
                    console.error(`Otc Trade rejected: ${rejected}`);
                    const actionMessages = mapOtcTradesApiErrorToActionMessages(rejected);
                    actionMessages.forEach((actionMessage: ActionMessage) => dispatch(setActionMessage(actionMessage)));
                });
        }
    };

    const onOtcTradesBuySellRadioButtonClick = (selectedValue: number) => {
        dispatch(setOtcTradesFormState({ ...otcTradesFormState, buySellValue: selectedValue }));
    };

    const onBrokerageCheckboxClick = (e: boolean) => {
        dispatch(setOtcTradesFormState({ ...otcTradesFormState, brokerage: e }));
    };

    const onOtcTradesVolumeTextEntry = (callbackArray: string[]) => {
        let inputValue;
        if (callbackArray[0].indexOf(',') !== -1) {
            inputValue = Number(callbackArray[0].replaceAll(',', ''));
        } else {
            inputValue = Number(callbackArray[0]);
        }
        dispatch(setOtcTradesFormState({ ...otcTradesFormState, totalAllocatedVolume: inputValue }));
    };

    const onOtcTradesPriceTextEntry = (callbackArray: string[]) => {
        const inputValue = Number(callbackArray[0]);
        dispatch(setOtcTradesFormState({ ...otcTradesFormState, allocatedPrice: inputValue }));
    };

    const readAllocatedTherms = (therms: number, destination: string) => {
        useEffect(() => {
            const updatedOtcTradesDestinationThermCounts = [];
            for (let i = 0; i < destinationThermCounts.length; i += 1) {
                const destinationThermCountClone = { ...destinationThermCounts[i] };
                updatedOtcTradesDestinationThermCounts.push(destinationThermCountClone);
            }
            for (let i = 0; i < updatedOtcTradesDestinationThermCounts.length; i += 1) {
                if (updatedOtcTradesDestinationThermCounts[i].requestText === destination) {
                    updatedOtcTradesDestinationThermCounts[i].thermsAllocated = therms;
                }
            }
            let combinedThermsAllocated = 0;
            for (let i = 0; i < updatedOtcTradesDestinationThermCounts.length; i += 1) {
                combinedThermsAllocated += updatedOtcTradesDestinationThermCounts[i].thermsAllocated;
            }
            const currentRemainingTherms = otcTradesTotalThermsAvailable - combinedThermsAllocated;
            if (currentRemainingTherms < 0) {
                for (let i = 0; i < updatedOtcTradesDestinationThermCounts.length; i += 1) {
                    if (updatedOtcTradesDestinationThermCounts[i].requestText === destination) {
                        updatedOtcTradesDestinationThermCounts[i].isTooManyTherms = true;
                    }
                }
            } else {
                for (let i = 0; i < updatedOtcTradesDestinationThermCounts.length; i += 1) {
                    updatedOtcTradesDestinationThermCounts[i].isTooManyTherms = false;
                }
            }
            dispatch(
                setOtcTradesFormState({
                    ...otcTradesFormState,
                    destinationThermCounts: updatedOtcTradesDestinationThermCounts
                })
            );
        }, [therms]);
    };

    const otcTradesDestinationElements: JSX.Element[] = [];
    destinationThermCounts?.forEach((otcTradesDestinationWithTherms) => {
        const { displayText, requestText } = otcTradesDestinationWithTherms;
        const destinationElementUniqueId = `otc-trades-destination-${requestText.toLowerCase()}`;

        let isTooManyThermsFlag = false;
        for (let i = 0; i < destinationThermCounts.length; i += 1) {
            if (
                destinationThermCounts[i].requestText === requestText &&
                destinationThermCounts[i].isTooManyTherms &&
                destinationThermCounts[i].thermsAllocated !== 0
            ) {
                isTooManyThermsFlag = true;
            }
        }

        // Generate the relevant jsx for each allocation destination
        const otcTradesDestinationItem = (
            <AllocationDestinationItem
                id={destinationElementUniqueId}
                key={destinationElementUniqueId}
                requestText={requestText}
                displayText={displayText}
                // @ts-ignore
                initialTherms={otcTradesDestinationWithTherms.thermsAllocated}
                remainingTherms={otcTradesRemainingTherms}
                externalErrorFlag={isTooManyThermsFlag}
                externalTextboxRerenderFlag={formPreviouslySubmitted}
                onChange={readAllocatedTherms}
            />
        );
        otcTradesDestinationElements.push(otcTradesDestinationItem);
    });

    const onOtcTradesDateSelection = (date: any) => {
        dispatch(setOtcTradesFormState({ ...otcTradesFormState, selectedDate: date.toIsoString() }));
    };

    return (
        <OtcTradesPanelContainer>
            <OtcTradesPanelContainer>
                <OtcTradesPanelHeaderContainer>
                    <TraderIdField
                        id="drawer-trader-id-field"
                        externalErrorFlag={showErrorMsg}
                        onChange={checkTraderIdError}
                    />
                </OtcTradesPanelHeaderContainer>
            </OtcTradesPanelContainer>

            <OtcTradesTimeSelectionContainer>
                <div id="otc-trades-day-and-product-input-wrapper">
                    <OtcTradesGasDayFieldContainer>
                        <Paragraph id="otc-trades-gas-day-heading">Trade date</Paragraph>
                        {/* @ts-ignore */}
                        <DatePicker
                            id="otc-trades-gas-day-field"
                            value={new DatePickerDateTime(selectedDate)}
                            onChange={onOtcTradesDateSelection}
                            dateBoxSize="sm"
                            // @ts-ignore
                            calendarSize="xs"
                        />
                    </OtcTradesGasDayFieldContainer>
                    <OtcTradesSegmentSelectionMenuWrapper>
                        <Paragraph>Product</Paragraph>
                        <select
                            id="otc-trades-product-field"
                            data-testid="otc-trades-product-field"
                            value={selectedProduct}
                            onChange={(event) => {
                                dispatch(
                                    setOtcTradesFormState({
                                        ...otcTradesFormState,
                                        selectedProduct: event.target.value
                                    })
                                );
                            }}
                        >
                            <option
                                key="otc-trades-product-option-wd"
                                data-testid="otc-trades-product-option-wd"
                                value="WD"
                            >
                                WD
                            </option>
                            <option
                                key="otc-trades-product-option-da"
                                data-testid="otc-trades-product-option-da"
                                value="DA"
                            >
                                DA
                            </option>
                        </select>
                    </OtcTradesSegmentSelectionMenuWrapper>
                </div>

                <div
                    id="otc-trades-time-selection-displays-wrapper"
                    data-testid="otc-trades-time-selection-displays-wrapper"
                >
                    <OtcTradesTimeSelectionDateDisplay key="otcTradesStartDate">
                        <span>Start date & time</span>
                        <span>{otcTradesDeliveryDates[0].toSimpleWordDateFormat()} • 05:00</span>
                    </OtcTradesTimeSelectionDateDisplay>
                    <OtcTradesTimeSelectionDateDisplay key="otcTradesEndDate">
                        <span>End date & time</span>
                        <span>{otcTradesDeliveryDates[1].toSimpleWordDateFormat()} • 05:00</span>
                    </OtcTradesTimeSelectionDateDisplay>
                </div>
            </OtcTradesTimeSelectionContainer>

            <OtcTradesBuySellContainer>
                <RadioButtonGroup
                    id="otc-trades-buy-sell-radio-button-group"
                    items={[
                        { value: 0, text: 'Buy' },
                        { value: 1, text: 'Sell' }
                    ]}
                    selectedValue={buySellValue}
                    onChange={onOtcTradesBuySellRadioButtonClick}
                />
                <div id="otc-trades-segment-counterparty-display-wrapper">
                    <div>
                        <Paragraph>Segment</Paragraph>
                        <Paragraph>EDF</Paragraph>
                    </div>
                    <Label id="otc-trades-buy-sell" color="blue">
                        {buySellValue === 0 ? 'buys from' : 'sells to'}
                    </Label>
                    <div>
                        <Paragraph>Counterparty</Paragraph>
                        <Paragraph>EDFT</Paragraph>
                    </div>
                </div>
                <CheckBox
                    checked={brokerage}
                    label="Brokerage"
                    id="otc-trades-brokerage-label"
                    onChange={(e: boolean) => onBrokerageCheckboxClick(e)}
                />
            </OtcTradesBuySellContainer>

            <OtcTradesVolumeEntryContainer>
                <TextInput
                    key={`OtcTradesTextInputVolume-${formPreviouslySubmitted}`}
                    fieldName="Volume"
                    initialValue={totalAllocatedVolume === 0 ? '' : String(totalAllocatedVolume)}
                    units="THM"
                    htmlId="otc-trades-volume-field"
                    hasError={false}
                    onTextInput={onOtcTradesVolumeTextEntry}
                    type="number"
                    disableNegativeNumbers
                    disableDecimals
                    formatNumberCommas
                />
                <span>@</span>
                <TextInput
                    key={`OtcTradesTextInputPrice-${formPreviouslySubmitted}`}
                    fieldName="Price"
                    initialValue={allocatedPrice === 0 ? '' : String(allocatedPrice)}
                    units="£"
                    htmlId="otc-trades-price-field"
                    hasError={false}
                    onTextInput={onOtcTradesPriceTextEntry}
                    type="number"
                    disableNegativeNumbers
                    decimalPlaces={4}
                />
            </OtcTradesVolumeEntryContainer>

            <OtcTradesDestinationElementsContainer>
                {otcTradesDestinationElements}
                <Paragraph id="otc-trades-remaining-amount">
                    Remaining:
                    <span>{Number(otcTradesRemainingTherms.toFixed(0)).toLocaleString('en-US')}</span>
                </Paragraph>
            </OtcTradesDestinationElementsContainer>

            <OtcTradesConfirmButtonContainer>
                {otcTradesVolumeError && (
                    <ValidationMessage
                        key="otcTradesValidationVolume"
                        id="otc-trades-volume-error-message"
                        message="Please enter a volume"
                        messageType="error"
                        iconName="warning"
                    />
                )}
                {otcTradesPriceWarning && (
                    <ValidationMessage
                        key="otcTradesValidationPriceWarning"
                        id="otc-trades-price-warning-message"
                        message="Warning: Price entered is above £10.00"
                        messageType="message"
                        iconName="warning"
                    />
                )}
                {otcTradesPriceError && (
                    <ValidationMessage
                        key="otcTradesValidationPrice"
                        id="otc-trades-price-error-message"
                        message="Please enter a price"
                        messageType="error"
                        iconName="warning"
                    />
                )}
                {otcTradesRemainingThermsError && (
                    <ValidationMessage
                        key="otcTradesValidationRemainingTherms"
                        id="otc-trades-remaining-therms-nonzero-error-message"
                        message="Please allocate remaining volume to zero"
                        messageType="error"
                        iconName="warning"
                    />
                )}
                {traderIdError && (
                    <ValidationMessage
                        key="otcTradesValidationTraderId"
                        id="otc-trades-trader-id-error-message"
                        message="Please enter a valid trader ID"
                        messageType="error"
                        iconName="warning"
                    />
                )}
                <OtcTradesConfirmButtonWrapper>
                    <Button
                        key="otcTradesConfirmButton"
                        id="otc-trades-confirm-button"
                        label="Confirm Allocation"
                        disabled={
                            isUpdatingOtc ||
                            otcTradesRemainingThermsError ||
                            otcTradesVolumeError ||
                            traderIdError ||
                            otcTradesPriceError ||
                            otcTradesRemainingTherms !== 0
                        }
                        type="button"
                        size="sm"
                        onClick={() => onConfirmAllocateOtcTradesClick(traderIdError)}
                    />
                    <Button
                        key="otcTradesCancelButton"
                        id="otc-trades-cancel-button"
                        label="Close & Cancel"
                        colorScheme="secondary"
                        type="button"
                        size="sm"
                        onClick={onCloseTradesPanel}
                    />
                </OtcTradesConfirmButtonWrapper>
            </OtcTradesConfirmButtonContainer>
        </OtcTradesPanelContainer>
    );
};

export { OtcTradesPanel };

import round from 'lodash';
import { DateTime } from '../../../../common/dateTime';
import { capitaliseFirstLetter } from '../../../../common/utilities';
import {
    IceTrade,
    IceTradesSplitAllocationThermsInputtedForCounterparty,
    IceTradesPostAllocateFullRequest
} from '../../types';
import { IceTradesPostAllocateSplitRequest, IceTradeAllocationVolumeAndDestination } from '../../types/IceTrade';
import { generatePostIceTradesAllocateFullRequest } from './generatePostIceTradesAllocateFullRequest';

// This function is called once per trade selected on the UI, it requires both GLOBAL and LOCAL info.
// GLOBAL info is shared by calls in same submission, despite multiple trades (same button press on UI)
// LOCAL info is specific to the trade selected
const generatePostIceTradesAllocateSplitRequest = (
    // LOCAL
    selectedIceTrade: IceTrade,
    // GLOBAL
    totalVolumeOfSelectedIceTrades: number,
    // GLOBAL: total volumes to allocate to each counterparty across all trades (e.g. what user inputs on UI)
    IceTradesSplitAllocationThermsInputtedForAllCounterparties: IceTradesSplitAllocationThermsInputtedForCounterparty[],
    // GLOBAL
    traderId: string
): IceTradesPostAllocateSplitRequest | IceTradesPostAllocateFullRequest => {
    let isSingleDestinationAllocated = false;
    let singleDestinationAllocatedValue = 0;
    for (let i = 0; i < IceTradesSplitAllocationThermsInputtedForAllCounterparties.length; i += 1) {
        if (
            IceTradesSplitAllocationThermsInputtedForAllCounterparties[i].thermsAllocated ===
            totalVolumeOfSelectedIceTrades
        ) {
            // This occurs when a trader selects "allocate remaining" in split allocation screen
            isSingleDestinationAllocated = true;
            singleDestinationAllocatedValue = i;
            break;
        }
    }
    // If a trader allocates to a single counterparty, it generates a Full Allocation request
    if (isSingleDestinationAllocated) {
        return generatePostIceTradesAllocateFullRequest(
            [selectedIceTrade],
            IceTradesSplitAllocationThermsInputtedForAllCounterparties[singleDestinationAllocatedValue].requestText,
            traderId
        );
    }

    const positiveVolumesAndDestinationsForAllSelectedTrades: IceTradeAllocationVolumeAndDestination[] =
        IceTradesSplitAllocationThermsInputtedForAllCounterparties.map(
            (splitAllocationDestinationThermCount: IceTradesSplitAllocationThermsInputtedForCounterparty) => {
                return {
                    volumeTherms: splitAllocationDestinationThermCount.thermsAllocated,
                    allocatedTo: splitAllocationDestinationThermCount.requestText
                };
            }
        ).filter(
            (volumeAndDestination: IceTradeAllocationVolumeAndDestination) => volumeAndDestination.volumeTherms > 0
        );

    const proportionedVolumesAndDestinations: IceTradeAllocationVolumeAndDestination[] =
        positiveVolumesAndDestinationsForAllSelectedTrades.map(
            (volumeAndDestination: IceTradeAllocationVolumeAndDestination) => {
                const volumeProportion = volumeAndDestination.volumeTherms / totalVolumeOfSelectedIceTrades;
                return {
                    // round to 6dp here - this is what's done in Power
                    volumeTherms: round.round(selectedIceTrade.volumeTherms * volumeProportion, 6),
                    allocatedTo: volumeAndDestination.allocatedTo
                };
            }
        );
    // this object describes how a single trade is split allocated between multiple counterparties
    return {
        dealId: selectedIceTrade.dealId,
        deliveryStartDate: new DateTime(selectedIceTrade.deliveryStartDate).toIsoDateFormat(),
        buySell: capitaliseFirstLetter(selectedIceTrade.buySell.toLowerCase()),
        totalVolumeTherms: parseFloat(String(selectedIceTrade.volumeTherms)),
        priceGBP: parseFloat(String(selectedIceTrade.priceGBP)),
        traderId,
        allocationStatus: selectedIceTrade.allocationStatus,
        volumeDestinations: proportionedVolumesAndDestinations
    };
};

export { generatePostIceTradesAllocateSplitRequest };

import { IceTrade } from '../../features/iceTrades/types/IceTrade';
import { BUY_SELL, GAS_ALLOCATION_TYPE, TRADE_ALLOCATION_STATUS } from '../types';
import { AllocationAligneImportResult, Status } from '../types/AligneImportResult';

const iceTradeItemDealIds: number[] = [9876543, 98765432, 987654, 987655, 1234, 78654, 2468, 8434];

export const iceTradeAllocationAligneImportFailedResultDefault: AllocationAligneImportResult = {
    correlationId: iceTradeItemDealIds[5].toString(),
    allocationType: GAS_ALLOCATION_TYPE.ICE,
    importResults: [
        {
            status: Status.FAILURE,
            statusMessage: 'general failure',
            errorMessage: 'some default error message',
            warningMessage: 'Invalid value',
            aligneFieldsReturned: [
                { fieldName: 'tpowTpl', value: 'EDF_GAS_PHYSICALS', comment: 'The trade template' },
                { fieldName: 'Record Index', value: '1', comment: 'Record index from the import file' }
            ]
        }
    ]
};

export const iceTradeAllocationAligneImportFailedResultTraderId: AllocationAligneImportResult = {
    correlationId: iceTradeItemDealIds[6].toString(),
    allocationType: GAS_ALLOCATION_TYPE.ICE,
    importResults: [
        {
            status: Status.FAILURE,
            statusMessage: 'general failure',
            errorMessage: 'Invalid fields{trader=AA11}',
            warningMessage: "Invalid value 'AA11' specified for field TRADER.",
            aligneFieldsReturned: [
                { fieldName: 'tpowTpl', value: 'EDF_GAS_PHYSICALS', comment: 'The trade template' },
                { fieldName: 'Record Index', value: '1', comment: 'Record index from the import file' }
            ]
        }
    ]
};

export const iceTradesItemsMockData: IceTrade[] = [
    {
        deliveryStartDate: '2022-03-14T00:00:00.000Z',
        dealId: iceTradeItemDealIds[0],
        tradeDate: '2022-03-15T00:00:00.000Z',
        buySell: BUY_SELL.SELL,
        volumeTherms: 30,
        priceGBP: 1.5677,
        allocationStatus: TRADE_ALLOCATION_STATUS.UNALLOCATED
    },
    {
        deliveryStartDate: '2022-03-15T00:00:00.000Z',
        dealId: iceTradeItemDealIds[1],
        tradeDate: '2022-03-17T00:00:00.000Z',
        buySell: BUY_SELL.BUY,
        volumeTherms: 100000,
        priceGBP: 1,
        allocationStatus: TRADE_ALLOCATION_STATUS.UNALLOCATED
    },
    {
        deliveryStartDate: '2022-03-14T00:00:00.000Z',
        dealId: iceTradeItemDealIds[2],
        tradeDate: '2022-03-15T00:00:00.000Z',
        buySell: BUY_SELL.SELL,
        volumeTherms: 20000,
        priceGBP: 1.5677,
        allocationStatus: TRADE_ALLOCATION_STATUS.UNALLOCATED
    },
    {
        deliveryStartDate: '2022-03-15T00:00:00.000Z',
        dealId: iceTradeItemDealIds[3],
        tradeDate: '2022-03-16T00:00:00.000Z',
        buySell: BUY_SELL.SELL,
        volumeTherms: 40000,
        priceGBP: 1.8322,
        allocationStatus: TRADE_ALLOCATION_STATUS.UNALLOCATED
    },
    {
        deliveryStartDate: '2022-03-18T00:00:00.000Z',
        dealId: iceTradeItemDealIds[4],
        tradeDate: '2022-03-19T00:00:00.000Z',
        buySell: BUY_SELL.SELL,
        volumeTherms: 20000,
        priceGBP: 1.5322,
        allocationStatus: TRADE_ALLOCATION_STATUS.PENDING
    },
    {
        deliveryStartDate: '2022-03-16T00:00:00.000Z',
        dealId: iceTradeItemDealIds[5],
        tradeDate: '2022-03-17T00:00:00.000Z',
        buySell: BUY_SELL.SELL,
        volumeTherms: 14500,
        priceGBP: 1.3328,
        allocationStatus: TRADE_ALLOCATION_STATUS.FAILED,
        aligneImportResults: iceTradeAllocationAligneImportFailedResultDefault
    },
    {
        deliveryStartDate: '2022-03-19T00:00:00.000Z',
        dealId: iceTradeItemDealIds[6],
        tradeDate: '2022-03-20T00:00:00.000Z',
        buySell: BUY_SELL.SELL,
        volumeTherms: 8900,
        priceGBP: 0.945,
        allocationStatus: TRADE_ALLOCATION_STATUS.FAILED,
        aligneImportResults: iceTradeAllocationAligneImportFailedResultTraderId
    }
];

export const iceTradeAllocationAligneImportSuccessResult: AllocationAligneImportResult = {
    correlationId: iceTradeItemDealIds[0].toString(),
    allocationType: GAS_ALLOCATION_TYPE.ICE,
    importResults: [
        {
            status: Status.SUCCESS,
            successMessage: 'success',
            aligneFieldsReturned: [
                {
                    fieldName: 'foo',
                    value: 'bar',
                    comment: 'bobbins'
                }
            ]
        }
    ]
};

// Helper function to return the trades from the mock dataset given certain conditions
export const findTradesGivenAllocationStatus = (status: string, without: boolean = false) => {
    const upperCaseStatus = status.toUpperCase();
    const resultantTrades = [];
    let statusCheck;
    for (let i = 0; i < iceTradesItemsMockData.length; i += 1) {
        statusCheck = without
            ? iceTradesItemsMockData[i].allocationStatus !== upperCaseStatus
            : iceTradesItemsMockData[i].allocationStatus === upperCaseStatus;
        if (statusCheck) {
            resultantTrades.push(iceTradesItemsMockData[i]);
        }
    }
    return resultantTrades;
};

export type ApiResponse<T> = {
    isSuccess: boolean;
    isError: boolean;
    status: string;
    data: T;
    error: { status: string; error: string };
};

export type IceTradesUnallocatedGetDataResponse = { iceTrades: IceTrade[]; lastTimeChecked: string };

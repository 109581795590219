import styled from 'styled-components/macro';

const OtcTradesPanelContainer = styled.div``;

const OtcTradesPanelHeaderContainer = styled.div`
    margin: 12px;
    & #drawer-trader-id-field {
        display: flex;
        height: 73px;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 25px;
        border-bottom: solid 1px #b9b9b9;
        padding: 0px;
        & span {
            display: none;
        }
    }
`;

const OtcTradesTimeSelectionContainer = styled.div`
    margin: 25px 12px;
    border-bottom: solid 1px #b9b9b9;
    & label > div:first-of-type {
        padding: 0px;
        margin-bottom: 5px;
    }
    & #otc-trades-gas-day-field {
        height: 28px;
        width: 152px;
        border-bottom: 1px solid #${({ theme }) => theme.colors.datePicker.internal.border};
        & > div:first-of-type {
            padding: 10px 8px;
            & span {
                color: black;
            }
        }
        & > div:last-of-type {
            z-index: 999;
            left: 0px;
        }
    }
    & #otc-trades-time-selection-displays-wrapper {
        margin: 12px 0px 25px 0px;
        display: flex;
        & div:last-of-type {
            margin-left: 70px;
        }
    }
    & #otc-trades-day-and-product-input-wrapper {
        display: flex;
        justify-content: space-between;
        & #otc-trades-product-field {
            height: 28px;
            width: 152px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 0px;
        }
    }
`;

const OtcTradesGasDayFieldContainer = styled.div`
    border: none;
    & #otc-trades-gas-day-heading {
        color: #${({ theme }) => theme.colors.datePicker.internal.border};
        font-weight: 600;
        font-size: 12px;
        margin: 0px 0px 5px 0px;
        letter-spacing: 0.8px;
    }
    & > span {
        width: fit-content;
    }
    & #datepicker-previous,
    & #datepicker-next {
        display: flex;
    }
`;

const OtcTradesTimeSelectionDateDisplay = styled.div`
    display: flex;
    flex-direction: column;
    & span {
        :first-of-type {
            color: #${({ theme }) => theme.colors.supportiveText};
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.8px;
        }
        :last-of-type {
            font-weight: 600;
            font-size: 14px;
        }
    }
`;

const OtcTradesSegmentSelectionMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    & p {
        margin: 0px;
        margin-bottom: 5px;
        font-size: 12px;
        color: #${({ theme }) => theme.colors.supportiveText};
        letter-spacing: 0.8px;
        font-weight: 600;
    }
`;

const OtcTradesVolumeEntryContainer = styled.div`
    display: flex;
    margin: 25px 12px;
    justify-content: space-between;
    & span {
        position: relative;
        top: 23px;
        height: fit-content;
    }
    & label > div:first-of-type {
        padding: 0px;
        margin-bottom: 5px;
    }
    & #otc-trades-volume-field {
        height: 28px;
        width: 75px;
        border-color: #${({ theme }) => theme.colors.supportiveText};
    }
    & #otc-trades-price-field {
        height: 28px;
        width: 105px;
        padding-left: 0px;
        border-style: solid solid solid none;
        border-color: #${({ theme }) => theme.colors.supportiveText};
    }
    & #otc-trades-volume-field + div {
        border: 1px solid #${({ theme }) => theme.colors.supportiveText};
        border-left: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: black;
        padding-right: 3px;
    }
    & #otc-trades-price-field + div {
        border: 1px solid #${({ theme }) => theme.colors.supportiveText};
        border-right: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        padding-right: 3px;
        min-width: 20px;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 3px;
    }
    & div:has(#otc-trades-volume-field) {
        margin-bottom: 0px;
    }
    & div:has(#otc-trades-price-field) {
        flex-direction: row-reverse;
        margin-bottom: 0px;
    }
`;

const OtcTradesBuySellContainer = styled.div`
    & #otc-trades-buy-sell-radio-button-group {
        display: flex;
        margin: 12px;
        & label {
            margin: 0px;
            :last-of-type {
                margin-left: 12px;
            }
        }
    }
    & #otc-trades-segment-counterparty-display-wrapper {
        display: flex;
        margin: 16px 12px 17px;
        & span {
            margin: 0px 47px 0px 9px;
            position: relative;
            top: 24px;
            height: 17px;
            width: 75px;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.8px;
        }
    }
    & div {
        & p {
            margin: 0px;
            font-size: 12px;
            letter-spacing: 0.8px;
            font-weight: 600;
        }
        & p:first-of-type {
            margin-bottom: 5px;
            color: #${({ theme }) => theme.colors.supportiveText};
        }
        & p:last-of-type {
            color: #${({ theme }) => theme.colors.body};
        }
    }
    & #otc-trades-brokerage-label {
        margin-left: 12px;
        padding-bottom: 25px;
        border-bottom: solid 1px #b9b9b9;
    }
    & label > div:first-of-type {
        padding: 0px;
        margin-bottom: 5px;
    }
`;

const OtcTradesDestinationElementsContainer = styled.div`
    margin: 12px 12px;
    & #otc-trades-remaining-amount {
        font-size: 12px;
        font-weight: normal;
        margin: 0px 0px 4px 0px;
        color: #1c1c1c;
        & span {
            font-weight: 600;
            margin: 0px 0px 0px 8px;
        }
    }
`;

const OtcTradesConfirmButtonContainer = styled.div`
    margin: 4px 12px 12px 12px;
    & #otc-trades-trader-id-error-message,
    & #otc-trades-price-error-message,
    & #otc-trades-price-warning-message,
    & #otc-trades-volume-error-message,
    & #otc-trades-remaining-therms-nonzero-error-message {
        height: 17px;
        font-size: 12px;
        font-weight: 600;
        margin: 8px 0px 8px 0px;
        & #validationMsgIcon {
            height: 16px;
            font-size: 16px;
            margin: 1px 3.3px 0px 0.7px;
        }
    }
`;

const OtcTradesConfirmButtonWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    & #otc-trades-cancel-button {
        margin-right: 12px;
        letter-spacing: 0.8px;
    }
    & #otc-trades-confirm-button {
        letter-spacing: 0.8px;
    }
`;

export {
    OtcTradesGasDayFieldContainer,
    OtcTradesPanelContainer,
    OtcTradesPanelHeaderContainer,
    OtcTradesSegmentSelectionMenuWrapper,
    OtcTradesTimeSelectionContainer,
    OtcTradesTimeSelectionDateDisplay,
    OtcTradesBuySellContainer,
    OtcTradesVolumeEntryContainer,
    OtcTradesDestinationElementsContainer,
    OtcTradesConfirmButtonContainer,
    OtcTradesConfirmButtonWrapper
};

import {
    Button,
    Label,
    Paragraph,
    RadioButtonGroup,
    TextInput,
    DatePicker,
    DatePickerDateTime
} from '@edfenergy/shift-desk-wallace';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ValidationMessage } from '../../../../common/components/ValidationMessage';
import { DateTime } from '../../../../common/dateTime';
import { BUY_SELL, TradeAllocationDestinationsTextList, TRADE_ALLOCATION_DESTINATION } from '../../../../common/types';
import { capitaliseFirstLetter } from '../../../../common/utilities';
import { selectTraderId, selectTraderIdError, setTraderId, setTraderIdError } from '../../../user/userSlice';
import { TraderIdField } from '../../../user/components/TraderIdField';
import {
    InternalTransfersPanelContainer,
    InternalTransfersBuySellContainer,
    InternalTransfersConfirmButtonContainer,
    InternalTransfersConfirmButtonWrapper,
    InternalTransfersGasDayFieldContainer,
    InternalTransfersPanelHeaderContainer,
    InternalTransfersSegmentSelectionMenuWrapper,
    InternalTransfersTimeSelectionContainer,
    InternalTransfersTimeSelectionDateDisplay,
    InternalTransfersVolumeEntryContainer
} from './styles';
import { InternalTransfersPanelProps } from './types';
import { setTradesPanelOpen } from '../../../../common/TradesPanelSlice';
import { usePostInternalTransfersMutation } from '../../../../services/internalTransfersApi';
import { selectWebSocketReconnect, setWebSocketReconnect } from '../../../../services/zapiResponseSlice';
import { useGetZapiResponseQuery } from '../../../../services/zapiResponseApi';
import { mapInternalTransfersApiErrorToActionMessages } from '../../../actionMessages/maps';
import { ActionMessage } from '../../../actionMessages/types';
import { setActionMessage } from '../../../actionMessages/actionMessagesSlice';
import { selectInternalTransfersForm, setInternalTransfersFormState } from '../../internalTransfersSlice';

const InternalTransfersPanel: React.FC<InternalTransfersPanelProps> = () => {
    const dispatch = useAppDispatch();
    const traderId = useAppSelector(selectTraderId);
    const traderIdError = useAppSelector(selectTraderIdError);
    const webSocketReconnect = useAppSelector(selectWebSocketReconnect);

    const internalTransfersFormState = useAppSelector(selectInternalTransfersForm);

    const { selectedDate, buySellValue, selectedDestinationValues, allocatedVolume, allocatedPrice } =
        internalTransfersFormState;

    const { data, error, isError, isSuccess, isLoading } = useGetZapiResponseQuery(webSocketReconnect);

    const [postInternalTransfers, { isLoading: isUpdatingFull }] = usePostInternalTransfersMutation();

    const [showErrorMsg, setShowErrorMsg] = useState(false);

    const [internalTransfersVolumeError, setInternalTransfersVolumeError] = useState(false);
    const [internalTransfersPriceError, setInternalTransfersPriceError] = useState(false);
    const [internalTransfersPriceWarning, setInternalTransfersPriceWarning] = useState(false);

    const [formPreviouslySubmitted, setFormPreviouslySubmitted] = useState(false);

    useEffect(() => {
        if (selectedDate === '') {
            dispatch(
                setInternalTransfersFormState({
                    ...internalTransfersFormState,
                    selectedDate: new DateTime().toIsoString()
                })
            );
        }
    }, []);

    useEffect(() => {
        setInternalTransfersVolumeError(allocatedVolume <= 0);
    }, [allocatedVolume]);

    useEffect(() => {
        setInternalTransfersPriceError(allocatedPrice <= 0);
        setInternalTransfersPriceWarning(allocatedPrice >= 10);
    }, [allocatedPrice]);

    const onCloseTradesPanel = () => {
        dispatch(setTradesPanelOpen(false));
    };

    const checkTraderIdError = (traderId: string, hasError: boolean) => {
        let error = hasError;
        if (traderId.length < 4) {
            error = true;
        }

        useEffect(() => {
            dispatch(setTraderId(traderId));
        }, [traderId]);

        useEffect(() => {
            dispatch(setTraderIdError(error));
            if (traderId.length !== 0) {
                setShowErrorMsg(error);
            }
        }, [error]);

        return [traderId, hasError];
    };

    const onInternalTransfersBuySellRadioButtonClick = (selectedValue: number) => {
        dispatch(setInternalTransfersFormState({ ...internalTransfersFormState, buySellValue: selectedValue }));
    };

    const internalTransfersDestinationLeftHandSideElements: JSX.Element[] = [];
    TradeAllocationDestinationsTextList?.forEach((tradeAllocationDestination) => {
        const { displayText, requestText } = tradeAllocationDestination;
        if (!(requestText === selectedDestinationValues[1])) {
            const internalTransfersDestinationItem = (
                <option
                    key={`${requestText}-left`}
                    data-testid={`internal-transfers-left-segment-option-${requestText}`}
                    value={requestText}
                >
                    {displayText}
                </option>
            );
            internalTransfersDestinationLeftHandSideElements.push(internalTransfersDestinationItem);
        }
    });

    const internalTransfersDestinationRightHandSideElements: JSX.Element[] = [];
    TradeAllocationDestinationsTextList?.forEach((tradeAllocationDestination) => {
        const { displayText, requestText } = tradeAllocationDestination;
        if (!(requestText === selectedDestinationValues[0])) {
            const internalTransfersDestinationItem = (
                <option
                    key={`${requestText}-right`}
                    data-testid={`internal-transfers-right-segment-option-${requestText}`}
                    value={requestText}
                >
                    {displayText}
                </option>
            );
            internalTransfersDestinationRightHandSideElements.push(internalTransfersDestinationItem);
        }
    });

    const onConfirmAllocateInternalTransfersClick = (traderIdError: boolean) => {
        if (data?.isWebSocketConnected === false) {
            dispatch(setWebSocketReconnect(!webSocketReconnect));
        }
        if (!traderIdError && allocatedVolume !== 0 && allocatedPrice !== 0) {
            postInternalTransfers({
                traderId,
                deliveryStartDate: new DateTime(selectedDate).toIsoDateFormat(),
                allocatedFrom: selectedDestinationValues[0],
                allocatedTo: selectedDestinationValues[1],
                buySell: capitaliseFirstLetter(
                    (buySellValue === 0 ? BUY_SELL.BUY : BUY_SELL.SELL).toLowerCase()
                ) as BUY_SELL,
                volumeTherms: allocatedVolume,
                priceGBP: allocatedPrice
            })
                .unwrap()
                .then((fulfilled) => {
                    dispatch(
                        setInternalTransfersFormState({
                            ...internalTransfersFormState,
                            allocatedVolume: 0,
                            allocatedPrice: 0
                        })
                    );
                    setFormPreviouslySubmitted(!formPreviouslySubmitted);
                    console.log(fulfilled);
                })
                .catch((rejected) => {
                    console.error(`Internal Transfer rejected: ${rejected}`);
                    const actionMessages = mapInternalTransfersApiErrorToActionMessages(rejected);
                    actionMessages.forEach((actionMessage: ActionMessage) => dispatch(setActionMessage(actionMessage)));
                });
        }
    };

    const onInternalTransfersVolumeTextEntry = (callbackArray: string[]) => {
        let inputValue;
        if (callbackArray[0].indexOf(',') !== -1) {
            inputValue = Number(callbackArray[0].replaceAll(',', ''));
        } else {
            inputValue = Number(callbackArray[0]);
        }
        dispatch(setInternalTransfersFormState({ ...internalTransfersFormState, allocatedVolume: inputValue }));
    };

    const onInternalTransfersPriceTextEntry = (callbackArray: string[]) => {
        const inputValue = Number(callbackArray[0]);
        dispatch(setInternalTransfersFormState({ ...internalTransfersFormState, allocatedPrice: inputValue }));
    };

    const onInternalTransfersDateSelection = (date: any) => {
        dispatch(setInternalTransfersFormState({ ...internalTransfersFormState, selectedDate: date.toIsoString() }));
    };

    return (
        <InternalTransfersPanelContainer>
            <InternalTransfersPanelHeaderContainer>
                <TraderIdField
                    id="drawer-trader-id-field"
                    externalErrorFlag={showErrorMsg}
                    onChange={checkTraderIdError}
                />
            </InternalTransfersPanelHeaderContainer>
            <InternalTransfersTimeSelectionContainer>
                <InternalTransfersGasDayFieldContainer>
                    <Paragraph id="internal-transfers-gas-day-heading">Gas day</Paragraph>
                    {/* @ts-ignore */}
                    <DatePicker
                        id="internal-transfers-gas-day-field"
                        value={new DatePickerDateTime(selectedDate)}
                        onChange={onInternalTransfersDateSelection}
                        iterator
                        dateBoxSize="sm"
                        // @ts-ignore
                        calendarSize="xs"
                    />
                </InternalTransfersGasDayFieldContainer>
                <div
                    id="internal-transfers-time-selection-displays-wrapper"
                    data-testid="internal-transfers-time-selection-displays-wrapper"
                >
                    <InternalTransfersTimeSelectionDateDisplay key="internalTransfersStartDate">
                        <span>Start date & time</span>
                        <span>{new DateTime(selectedDate).toSimpleWordDateFormat()} • 05:00</span>
                    </InternalTransfersTimeSelectionDateDisplay>
                    <InternalTransfersTimeSelectionDateDisplay key="internalTransfersEndDate">
                        <span>End date & time</span>
                        <span>
                            {new DateTime(selectedDate)
                                .setDay(new DateTime(selectedDate).getDay() + 1)
                                .toSimpleWordDateFormat()}{' '}
                            • 05:00
                        </span>
                    </InternalTransfersTimeSelectionDateDisplay>
                </div>
            </InternalTransfersTimeSelectionContainer>
            <InternalTransfersBuySellContainer>
                <RadioButtonGroup
                    id="internal-transfers-buy-sell-radio-button-group"
                    items={[
                        { value: 0, text: 'Buy' },
                        { value: 1, text: 'Sell' }
                    ]}
                    selectedValue={buySellValue}
                    onChange={onInternalTransfersBuySellRadioButtonClick}
                />
                <div id="internal-transfers-segment-selection-wrapper">
                    <InternalTransfersSegmentSelectionMenuWrapper>
                        <Paragraph>Segment</Paragraph>
                        <select
                            id="internal-transfers-left-segment-field"
                            data-testid="internal-transfers-left-segment-field"
                            value={selectedDestinationValues[0]}
                            onChange={(event) => {
                                dispatch(
                                    setInternalTransfersFormState({
                                        ...internalTransfersFormState,
                                        selectedDestinationValues: [
                                            event.target.value as TRADE_ALLOCATION_DESTINATION,
                                            selectedDestinationValues[1]
                                        ]
                                    })
                                );
                            }}
                        >
                            {internalTransfersDestinationLeftHandSideElements}
                        </select>
                    </InternalTransfersSegmentSelectionMenuWrapper>
                    <Label id="internal-transfers-buy-sell" color="blue">
                        {buySellValue === 0 ? 'buys from' : 'sells to'}
                    </Label>
                    <InternalTransfersSegmentSelectionMenuWrapper>
                        <Paragraph>Segment</Paragraph>
                        <select
                            id="internal-transfers-right-segment-field"
                            data-testid="internal-transfers-right-segment-field"
                            value={selectedDestinationValues[1]}
                            onChange={(event) => {
                                dispatch(
                                    setInternalTransfersFormState({
                                        ...internalTransfersFormState,
                                        selectedDestinationValues: [
                                            selectedDestinationValues[0],
                                            event.target.value as TRADE_ALLOCATION_DESTINATION
                                        ]
                                    })
                                );
                            }}
                        >
                            {internalTransfersDestinationRightHandSideElements}
                        </select>
                    </InternalTransfersSegmentSelectionMenuWrapper>
                </div>
            </InternalTransfersBuySellContainer>
            <InternalTransfersVolumeEntryContainer>
                <TextInput
                    key={`internalTransfersTextInputVolume-${formPreviouslySubmitted}`}
                    fieldName="Volume"
                    initialValue={allocatedVolume === 0 ? '' : String(allocatedVolume)}
                    units="THM"
                    htmlId="internal-transfers-volume-field"
                    hasError={false}
                    onTextInput={onInternalTransfersVolumeTextEntry}
                    type="number"
                    disableNegativeNumbers
                    disableDecimals
                    formatNumberCommas
                />
                <span>@</span>
                <TextInput
                    key={`internalTransfersTextInputPrice-${formPreviouslySubmitted}`}
                    fieldName="Price"
                    initialValue={allocatedPrice === 0 ? '' : String(allocatedPrice)}
                    units="£"
                    htmlId="internal-transfers-price-field"
                    hasError={false}
                    onTextInput={onInternalTransfersPriceTextEntry}
                    type="number"
                    disableNegativeNumbers
                    decimalPlaces={4}
                />
            </InternalTransfersVolumeEntryContainer>
            <InternalTransfersConfirmButtonContainer>
                {internalTransfersVolumeError && (
                    <ValidationMessage
                        key="internalTransfersValidationVolume"
                        id="internal-transfers-volume-error-message"
                        message="Please enter a volume"
                        messageType="error"
                        iconName="warning"
                    />
                )}
                {internalTransfersPriceWarning && (
                    <ValidationMessage
                        key="internalTransfersValidationPriceWarning"
                        id="internal-transfers-price-warning-message"
                        message="Warning: Price entered is above £10.00"
                        messageType="message"
                        iconName="warning"
                    />
                )}
                {internalTransfersPriceError && (
                    <ValidationMessage
                        key="internalTransfersValidationPrice"
                        id="internal-transfers-price-error-message"
                        message="Please enter a price"
                        messageType="error"
                        iconName="warning"
                    />
                )}
                {traderIdError && (
                    <ValidationMessage
                        key="internalTransfersValidationTraderId"
                        id="internal-transfers-trader-id-error-message"
                        message="Please enter a valid trader ID"
                        messageType="error"
                        iconName="warning"
                    />
                )}
                <InternalTransfersConfirmButtonWrapper>
                    <Button
                        key="internalTransfersConfirmButton"
                        id="internal-transfers-confirm-button"
                        label={isUpdatingFull ? 'Loading...' : 'Confirm Allocation'}
                        disabled={
                            isUpdatingFull ||
                            internalTransfersVolumeError ||
                            traderIdError ||
                            internalTransfersPriceError
                        }
                        type="button"
                        size="sm"
                        onClick={() => onConfirmAllocateInternalTransfersClick(traderIdError)}
                    />
                    <Button
                        key="internalTransfersCancelButton"
                        id="internal-transfers-cancel-button"
                        label="Close & Cancel"
                        colorScheme="secondary"
                        type="button"
                        size="sm"
                        onClick={onCloseTradesPanel}
                    />
                </InternalTransfersConfirmButtonWrapper>
            </InternalTransfersConfirmButtonContainer>
        </InternalTransfersPanelContainer>
    );
};

export { InternalTransfersPanel };

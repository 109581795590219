// eslint-disable-next-line import/no-extraneous-dependencies
import { RequestHandler, rest } from 'msw';
import { iceTradesItemsMockData } from '../common/testData';
import { HTTP_STATUS_CODES } from '../common/http/types';
import { IceTradesPostAllocateFullRequest } from '../features/iceTrades/types';
import { CombinedSuccessAndFailureResponse } from '../common/types';
import { IceTradeFullAllocationRequest, IceTradesPostAllocateSplitRequest } from '../features/iceTrades/types/IceTrade';

export const getHandlers = (testBaseApiUrl: string): Array<RequestHandler<any, any>> => {
    return [
        // Handles a GET /icetrades/unallocated request
        rest.get(`${testBaseApiUrl}/api/v1/icetrades/unallocated`, (request, response, context) => {
            return response(
                // Respond with a 200 status code
                context.status(HTTP_STATUS_CODES.SUCCESS),
                context.json({ iceTrades: iceTradesItemsMockData })
            );
        }),

        // Handles a POST /icetrades/allocatefull request
        rest.post<IceTradesPostAllocateFullRequest, {}, CombinedSuccessAndFailureResponse<string>>(
            `${testBaseApiUrl}/api/v1/icetrades/allocatefull`,
            (request, response, context) => {
                // generate dynamic successful response based on trade ids
                const successes: string[] = request.body.map(
                    (iceTradeFullAllocationRequest: IceTradeFullAllocationRequest) =>
                        iceTradeFullAllocationRequest.dealId.toString()
                );
                return response(
                    // Respond with a 200 status code
                    context.status(HTTP_STATUS_CODES.SUCCESS),
                    context.json({ successes, failures: [] })
                );
            }
        ),

        // Handles a POST /icetrades/allocatesplit request
        rest.post<IceTradesPostAllocateSplitRequest, {}, CombinedSuccessAndFailureResponse<string>>(
            `${testBaseApiUrl}/api/v1/icetrades/allocatesplit`,
            (request, response, context) => {
                return response(
                    // Respond with a 200 status code
                    context.status(HTTP_STATUS_CODES.SUCCESS),
                    context.json({
                        successes: [request.body.dealId.toString()],
                        failures: []
                    })
                    // // Respond with a 500 status code to simulate error
                    // context.status(HTTP_STATUS_CODES.SERVER_ERROR),
                    // context.json({
                    //     successes: [],
                    //     failures: [
                    //         {
                    //             id: request.body.dealId.toString(),
                    //             failureReason: `Mock failure response | When processing record with dealId: ${request.body.dealId}`
                    //         }
                    //     ]
                    // })
                );
            }
        ),

        // Handles a POST /internalTransfers request
        rest.post(`${testBaseApiUrl}/api/v1/internaltransfers/allocate`, (request, response, context) => {
            return response(
                // Respond with a 200 status code
                context.status(HTTP_STATUS_CODES.SUCCESS),
                context.json({})
                // Respond with a 500 status code
                // context.status(HTTP_STATUS_CODES.SERVER_ERROR),
                // context.json({
                //     successes: [],
                //     failures: [
                //         {
                //             id: '1234567',
                //             failureReason: `Mock failure response | When processing internal transfer with dealId: 1234567`
                //         }
                //     ]
                // })
            );
        }),

        // Handles a POST /otcTrades request
        rest.post(`${testBaseApiUrl}/api/v1/otc/allocate`, (request, response, context) => {
            return response(
                // Respond with a 200 status code
                context.status(HTTP_STATUS_CODES.SUCCESS),
                context.json({})
                // Respond with a 500 status code
                // context.status(HTTP_STATUS_CODES.SERVER_ERROR),
                // context.json({
                //     successes: [],
                //     failures: [
                //         {
                //             id: '1234567',
                //             failureReason: `Mock failure response | When processing internal transfer with dealId: 1234567`
                //         }
                //     ]
                // })
            );
        })
    ];
};

import React from 'react';
import { toast } from 'react-toastify';
import { Toast } from '@edfenergy/shift-desk-wallace';
import 'react-toastify/dist/ReactToastify.css';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTION_MESSAGES_TYPES, ActionMessage } from './types';
import { RootState } from '../../app/store';
import { ActionMessageState } from './types/ActionMessagesState';

const initialState: ActionMessageState = {
    actionMessage: {
        type: ACTION_MESSAGES_TYPES.SUCCESS,
        message: ''
    }
};

export const actionMessagesSlice = createSlice({
    name: 'actionMessages',
    initialState,
    reducers: {
        // The location of this toast command and functionality should not exist here.
        // It was originally built to exist in an ActionMessagesContainer component,
        // however, it was not possible to stop the toast calls from Redux
        // duplicating the number of toast.
        setActionMessage: (state, action: PayloadAction<ActionMessage>) => {
            type MessageType = 'success' | 'error';
            const actionMessage = action.payload;
            let messageType: MessageType = 'success';
            let autoClose: false | number | undefined = 5000;
            if (actionMessage.type === ACTION_MESSAGES_TYPES.ERROR) {
                messageType = 'error';
                autoClose = false;
            }
            toast(
                <Toast
                    id="action-messages-toast-element"
                    className="action-messages-toast-element"
                    messageType={messageType}
                >
                    {actionMessage.message}
                </Toast>,
                {
                    className: `action-messages-toast-wrapper`,
                    autoClose,
                    closeButton: false,
                    pauseOnHover: true
                }
            );
            return { ...state, actionMessage: action.payload };
        }
    }
});

export const { setActionMessage } = actionMessagesSlice.actions;

const inputSelectActionMessage = (state: RootState) => state.actionMessages.actionMessage;

export const selectActionMessage = createSelector(
    inputSelectActionMessage,
    (actionMessage: ActionMessage) => actionMessage
);

export default actionMessagesSlice.reducer;

import { DateTime } from '../../../../common/dateTime';
import { TRADE_ALLOCATION_DESTINATION } from '../../../../common/types';
import { capitaliseFirstLetter } from '../../../../common/utilities';
import { IceTrade, IceTradesPostAllocateFullRequest } from '../../types';
import { IceTradeFullAllocationRequest } from '../../types/IceTrade';

const generatePostIceTradesAllocateFullRequest = (
    selectedIceTrades: IceTrade[],
    allocationDestination: TRADE_ALLOCATION_DESTINATION,
    traderId: string
): IceTradesPostAllocateFullRequest => {
    return selectedIceTrades.map((iceTrade: IceTrade) => {
        const iceTradeWithAllocationDestination: IceTradeFullAllocationRequest = {
            dealId: iceTrade.dealId,
            tradeDate: new DateTime(iceTrade.tradeDate).toIsoDateFormat(),
            buySell: capitaliseFirstLetter(iceTrade.buySell.toLowerCase()),
            volumeTherms: parseFloat(String(iceTrade.volumeTherms)),
            priceGBP: parseFloat(String(iceTrade.priceGBP)),
            deliveryStartDate: new DateTime(iceTrade.deliveryStartDate).toIsoDateFormat(),
            allocatedTo: allocationDestination,
            traderId,
            allocationStatus: iceTrade.allocationStatus
        };
        return iceTradeWithAllocationDestination;
    });
};

export { generatePostIceTradesAllocateFullRequest };

import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { themes } from '@edfenergy/shift-desk-wallace';
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';

import * as serviceWorker from './serviceWorker';

import App from './App';

import { setupStore } from './app/store';

import reportWebVitals from './reportWebVitals';
import { worker } from './mocks/browser';
import { isSpecified } from './common/utilities';
import { config } from './common/config';
import GlobalStyle from './theme/globalStyle';

async function main() {
    try {
        if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_SERVER_MOCKED === 'true') {
            await worker.start();
        }

        if (
            !isSpecified(process.env.REACT_APP_SKIP_AUTH) ||
            (isSpecified(process.env.REACT_APP_SKIP_AUTH) && process.env.REACT_APP_SKIP_AUTH === 'false')
        ) {
            Amplify.configure(config.Auth);
        }

        ReactDOM.render(
            <React.StrictMode>
                <Provider store={setupStore()}>
                    <ThemeProvider theme={themes.axiomLightTheme}>
                        <GlobalStyle />
                        <App />
                    </ThemeProvider>
                </Provider>
            </React.StrictMode>,
            document.getElementById('gasApp')
        );
    } catch (error) {
        console.error('App could not be loaded');
        console.error(error);
    }
}

main().then();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: onServiceWorkerUpdate
});

import React, { useEffect, useState } from 'react';
import { TextInput } from '@edfenergy/shift-desk-wallace';

import { TraderIdFieldContainer } from './styles';
import { TraderIdFieldProps } from './types';
import { ValidationMessage } from '../../../../common/components/ValidationMessage';
import { useAppSelector } from '../../../../app/hooks';
import { selectTraderId, selectTraderIdError } from '../../userSlice';

const incompleteValidTraderIdRegEx: RegExp = /^$|^[A-Z]{1,2}$|^[A-Z]{2}[0-9]{1,2}$/g;

const TraderIdField: React.FC<TraderIdFieldProps> = (props: TraderIdFieldProps) => {
    const { id, externalErrorFlag = false, onChange } = props;
    const initialVal: string = props.initialTraderId ? props.initialTraderId : useAppSelector(selectTraderId);

    let initialErrorState = false;
    if (!initialVal.match(incompleteValidTraderIdRegEx)) {
        initialErrorState = true;
    }

    const [hasError, setHasError] = useState<boolean>(initialErrorState);
    const [traderId, setTraderId] = useState<string>(initialVal);

    if (onChange) {
        onChange(traderId, hasError);
    }

    const onChangeTraderIdField = (callbackArray: string[]) => {
        const inputValue = callbackArray[0];
        setTraderId(inputValue);
        if (inputValue.length === 0) {
            setHasError(false);
            return;
        }
        if (!inputValue.match(incompleteValidTraderIdRegEx)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    };

    return (
        <TraderIdFieldContainer id={id} showErrorMessage={hasError || externalErrorFlag}>
            <TextInput
                initialValue={traderId}
                onTextInput={onChangeTraderIdField}
                fieldName="Trader ID"
                htmlId="traderId"
                hasError={hasError}
            />
            {(hasError || externalErrorFlag) && (
                <ValidationMessage message="Please enter a trader ID" messageType="error" iconName="warning" />
            )}
        </TraderIdFieldContainer>
    );
};

export { TraderIdField };

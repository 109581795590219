import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Functions } from '@edfenergy/res-agentui-sso-auth';
import { RootState } from '../../app/store';
import { setSession } from './authenticationSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_APIGATEWAY_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
        const { accessToken } = (getState() as RootState).authentication.session;
        if (accessToken) {
            headers.set('authorization', accessToken);
        }
        return headers;
    }
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const refreshResult = (await Functions.getCurrentSesssion()).getAccessToken().getJwtToken();

        if (refreshResult) {
            api.dispatch(setSession({ accessToken: refreshResult }));

            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
        } else {
            // TODO sort out logout
            // api.dispatch(logout());
        }
    }
    if (result.error) {
        return { error: result.error };
    }
    return result;
};

import React, { useEffect, useState } from 'react';
import { CheckBox, Label } from '@edfenergy/shift-desk-wallace';

import { DateTime } from '../../../../common/dateTime';
import { capitaliseFirstLetter } from '../../../../common/utilities';

import { IceTradeItemProps } from './types';
import { AllocationAligneImportResult, TRADE_ALLOCATION_STATUS } from '../../../../common/types';

import { IceTradeItemContainer } from './styles';
import {
    ICE_TRADE_ALLOCATION_FAILURE_REASON,
    ICE_TRADE_ALLOCATION_FAILURE_STRING,
    ICE_TRADE_ITEM_STATUS_MESSAGE
} from '../../types';

const IceTradeItem: React.FC<IceTradeItemProps> = ({ iceTrade, id, isTradeSelected = false, onChange = () => {} }) => {
    const [selected, setSelected] = useState<boolean>(isTradeSelected);

    useEffect(() => {
        setSelected(isTradeSelected);
    }, [selected]);

    const isTradePending = iceTrade.allocationStatus === TRADE_ALLOCATION_STATUS.PENDING;
    const isFailed = iceTrade.allocationStatus === TRADE_ALLOCATION_STATUS.FAILED;

    let failureMessage: string = '';
    if (isFailed && iceTrade.aligneImportResults) {
        failureMessage = getFailureMessage(iceTrade.aligneImportResults);
    }

    const onTradeItemClick = () => {
        if (!isTradePending) {
            setSelected(!isTradeSelected);
            if (onChange) {
                onChange(id, !isTradeSelected);
            }
        }
    };

    const priceDecimals = String(iceTrade.priceGBP).split('.')[1];
    const len = priceDecimals && priceDecimals.length > 2 ? priceDecimals.length : 2;

    return (
        <IceTradeItemContainer
            isTradePending={isTradePending}
            id={`${id}_ice_trade_item`}
            data-testid="ice_trade_item"
            onClick={onTradeItemClick}
        >
            <div id="ice-trade-item-row">
                <CheckBox
                    checked={isTradeSelected && !isTradePending}
                    label={iceTrade.dealId.toString()}
                    id={iceTrade.dealId.toString()}
                />
                <Label color="blue">{capitaliseFirstLetter(iceTrade.buySell.toLowerCase())}</Label>
                <span className="ice_trade_item_date" key="ice_trade_item_date">
                    {new DateTime(iceTrade.tradeDate).toSimpleWordDateFormat()}
                </span>
                <span className="ice_trade_item_volume" key="ice_trade_item_volume">
                    {Number(iceTrade.volumeTherms).toLocaleString('en-US')}
                </span>
                <span className="ice_trade_item_price" key="ice_trade_item_price">
                    £{Number(iceTrade.priceGBP).toFixed(len)}
                </span>
            </div>
            {isTradePending && (
                <Label id="ice-trade-pending-label" data-testid="ice-trade-pending-label" color="blue">
                    Pending
                </Label>
            )}
            {isFailed && (
                <Label id="ice-trade-failed-label" data-testid="ice-trade-failed-label" color="red">
                    <span>Failed allocation</span>
                    <br />
                    <span id="ice-trade-failed-response" data-testid="ice-trade-failed-response">
                        {failureMessage}
                    </span>
                </Label>
            )}
        </IceTradeItemContainer>
    );
};

export const getFailureMessage = (aligneImportResult: AllocationAligneImportResult): string => {
    let failureMessage: string;
    const failureReason = getFailureReason(JSON.stringify(aligneImportResult));
    switch (failureReason) {
        case ICE_TRADE_ALLOCATION_FAILURE_REASON.TRADER_ID:
            failureMessage = ICE_TRADE_ITEM_STATUS_MESSAGE.FAILED_TRADER_ID;
            break;
        default:
            failureMessage = ICE_TRADE_ITEM_STATUS_MESSAGE.FAILED_DEFAULT;
            break;
    }
    return failureMessage;
};

const getFailureReason = (failureResultString: string): ICE_TRADE_ALLOCATION_FAILURE_REASON => {
    if (failureResultString.includes(ICE_TRADE_ALLOCATION_FAILURE_STRING.TRADER_ID)) {
        return ICE_TRADE_ALLOCATION_FAILURE_REASON.TRADER_ID;
    }
    return ICE_TRADE_ALLOCATION_FAILURE_REASON.DEFAULT;
};

export { IceTradeItem };

import styled from 'styled-components';

const FooterContainer = styled.footer`
    font-size: 12px;
    text-align: right;
    padding: 5px;
    bottom: 0;
    position: absolute;
    right: 0;
`;

export default FooterContainer;

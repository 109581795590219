import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import type { PreloadedState } from '@reduxjs/toolkit';

// Slices
import { actionMessagesSlice } from '../features/actionMessages/actionMessagesSlice';
import { authenticationSlice } from '../features/authentication/authenticationSlice';
import { iceTradesSlice } from '../features/iceTrades/iceTradesSlice';
import { internalTransfersSlice } from '../features/internalTransfers/internalTransfersSlice';
import { otcTradesSlice } from '../features/otcTrades/otcTradesSlice';
import { userSlice } from '../features/user/userSlice';
// Services
import { iceTradesApi } from '../services/iceTradesApi';
import { tradesPanelSlice } from '../common/TradesPanelSlice';
import { internalTransfersApi } from '../services/internalTransfersApi';
import { zapiResponseApi } from '../services/zapiResponseApi';
import { zapiResponseSlice } from '../services/zapiResponseSlice';
import { otcTradesApi } from '../services/otcTradesApi';

const rootReducer = combineReducers({
    actionMessages: actionMessagesSlice.reducer,
    authentication: authenticationSlice.reducer,
    // Add the generated API services reducer as a specific top-level slice
    [iceTradesApi.reducerPath]: iceTradesApi.reducer,
    [otcTradesApi.reducerPath]: otcTradesApi.reducer,
    [internalTransfersApi.reducerPath]: internalTransfersApi.reducer,
    [zapiResponseApi.reducerPath]: zapiResponseApi.reducer,
    iceTrades: iceTradesSlice.reducer,
    internalTransfers: internalTransfersSlice.reducer,
    otcTrades: otcTradesSlice.reducer,
    tradesPanel: tradesPanelSlice.reducer,
    user: userSlice.reducer,
    zapiResponse: zapiResponseSlice.reducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(iceTradesApi.middleware)
                .concat(internalTransfersApi.middleware)
                .concat(zapiResponseApi.middleware)
                .concat(otcTradesApi.middleware),
        preloadedState
    });
};

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(setupStore().dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

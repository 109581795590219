import { FailureResponseItem, IceTradesPostResponse } from '../../../common/types';
import { ACTION_MESSAGES_TYPES, ActionMessage } from '../types';

const mapIceTradesAllocationApiErrorToActionMessages = (
    iceTradesPostResponse: IceTradesPostResponse
): ActionMessage[] => {
    let actionMessages: ActionMessage[] = [];
    actionMessages =
        iceTradesPostResponse.data &&
        iceTradesPostResponse.data.failures.map((failureResponseItem: FailureResponseItem) => {
            const message = `ICE Trade allocation unsuccessful for Trade ID ${failureResponseItem.id}. Error message: ${failureResponseItem.failureReason}`;
            return {
                type: ACTION_MESSAGES_TYPES.ERROR,
                message
            };
        });
    return actionMessages;
};

export { mapIceTradesAllocationApiErrorToActionMessages };

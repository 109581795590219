import styled, { css } from 'styled-components/macro';
import { IceTradeItemContainerProps } from './types';

const IceTradeItemContainer = styled.div<IceTradeItemContainerProps>`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 8px 11px 8px 8px;
    margin-bottom: 8px;
    box-shadow: 0 2px 12px 0 rgba(16, 54, 122, 0.2);
    ${(props) => {
        if (props.isTradePending) {
            return css`
                pointer-events: none;
            `;
        }
        return css``;
    }}
    & #ice-trade-item-row {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        > :first-child {
            width: 100px;
            user-select: auto;
            pointer-events: none;
            & label {
                cursor: pointer;
                pointer-events: none;
            }
        }
        span {
            font-size: 12px;
            letter-spacing: 0.8px;
            font-weight: 600;
            text-align: right;
        }
        & .ice_trade_item_date {
            width: 80px;
            margin-right: 10px;
        }
        & .ice_trade_item_volume {
            width: 60px;
            text-align: left;
        }
        & .ice_trade_item_price {
            width: 100px;
            font-weight: normal;
        }
    }
    & #ice-trade-pending-label {
        height: 17px;
        width: 61px;
        float: right;
        margin-top: 8px;
        margin-left: auto;
        font-size: 12px;
        letter-spacing: 0.8px;
        text-align: right;
        color: #666666;
        background-color: #f1f1f1;
    }
    & #ice-trade-failed-label {
        margin-top: 8px;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
        letter-spacing: 0.8px;
        color: #e32747;
        background-color: #e327473d;
        & #ice-trade-failed-response {
            font-weight: normal;
        }
    }
`;

export { IceTradeItemContainer };

import styled from 'styled-components/macro';

const ActionMessagesContainer = styled.div`
    --toastify-toast-min-height: 39px;
    --toastify-toast-width: 360px;
    & .action-messages-toast-wrapper {
        border-radius: 0;
        padding: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        & > div {
            padding: 0;
            margin: 0;
            & > div:last-child {
                height: 100%;
            }
        }
    }

    & .action-messages-toast-element {
        display: flex;
        align-items: center;

        & > div:first-of-type {
            padding: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & > div:last-of-type {
            font-size: 12px;
            font-weight: 600;
            color: #1c1c1c;
            padding: 15px;
        }

        & > span {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
`;

export { ActionMessagesContainer };

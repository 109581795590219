import React, { memo } from 'react';
import { AuthContainer } from '@edfenergy/res-agentui-sso-auth';

// eslint-disable-next-line import/no-unresolved
import { IAuthSession } from '@edfenergy/res-agentui-sso-auth/dist/IAuthSession';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { config } from '../../../common/config';
import { selectAuthenticationSession, setSession } from '../authenticationSlice';
import { UserSession } from '../types/AuthenticationState';
import { NopHeader } from './NopHeader';
import AppUpdateNotification from '../../../common/components/AppUpdateNotification';

type AuthProps = {
    children: React.ReactNode;
};

const Auth: React.FC<AuthProps> = ({ children }) => {
    const session = useAppSelector(selectAuthenticationSession);
    const dispatch = useAppDispatch();

    const onAuthSignIn = (SSOAuthSession: IAuthSession) => {
        const session: UserSession = {
            isValid: SSOAuthSession.session.isValid(),
            accessToken: SSOAuthSession.session.getAccessToken().getJwtToken()
        };
        dispatch(setSession(session));
    };

    return (
        // @ts-ignore
        <AuthContainer
            autoLogin
            onSignIn={onAuthSignIn}
            amplifyConfig={config}
            renderer={({ errorMessage, defaultRenderer }) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {errorMessage ? (
                        <div style={{ width: 500, height: 200 }}>
                            <p>
                                You don&apos;t appear to have access to the active directory needed to use Shift Desk
                                Platform. Don&apos;t worry, this is easy to fix. Please raise a request via{' '}
                                <a href="https://edfenergy.service-now.com/myit/?id=sc_cat_item&sys_id=436ba7badb244c10b33d2b43059619bb">
                                    Service Now
                                </a>
                                .
                            </p>
                            <p>
                                If you require any assistance with the raising of the request then please contact the{' '}
                                <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                                    {process.env.REACT_APP_SUPPORT_EMAIL}
                                </a>
                                .
                            </p>
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '30px', width: 500, height: 200 }}>
                            {defaultRenderer()}
                        </div>
                    )}
                </div>
            )}
        >
            <NopHeader />
            <AppUpdateNotification />
            {children}
        </AuthContainer>
    );
};

export default memo(Auth);

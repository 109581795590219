import { Button, TextInput } from '@edfenergy/shift-desk-wallace';
import React, { useEffect, useState } from 'react';
import { ValidationMessage } from '../../../../common/components/ValidationMessage';
import { AllocationDestinationContainer } from './styles';
import { AllocationDestinationProps } from './types';
import { useAppDispatch } from '../../../../app/hooks';

const AllocationDestinationItem: React.FC<AllocationDestinationProps> = ({
    id,
    requestText,
    displayText,
    remainingTherms,
    initialTherms,
    externalErrorFlag = false,
    externalTextboxRerenderFlag = false,
    onChange
}) => {
    const [therms, setTherms] = useState(initialTherms ?? 0);
    const [wasAllocateRemainingClicked, setWasAllocateRemainingClicked] = useState(false);

    useEffect(() => {
        setTherms(initialTherms ?? 0);
        setWasAllocateRemainingClicked(!wasAllocateRemainingClicked);
    }, [externalTextboxRerenderFlag]);

    const destination = requestText;

    if (onChange) {
        onChange(therms, destination);
    }

    const onChangeAllocationDestinationField = (callbackArray: string[]) => {
        let inputValue;
        if (callbackArray[0].indexOf(',') !== -1) {
            inputValue = Number(callbackArray[0].replaceAll(',', ''));
        } else {
            inputValue = Number(callbackArray[0]);
        }
        setTherms(inputValue);
    };

    const onAllocateRemainingButtonClick = () => {
        const updatedTherms = therms + remainingTherms;
        if (updatedTherms >= 0) {
            setTherms(updatedTherms);
            // Making use of the key attribute to force re-render of the TextInput component
            // This was done because changing the 'initialValue' prop in TextInput does not seem to trigger the re-render of that component
            setWasAllocateRemainingClicked(!wasAllocateRemainingClicked);
        }
    };

    return (
        <AllocationDestinationContainer id={id} data-testid={id}>
            <div>
                <span data-testid="allocation-destination-label">{displayText}</span>
                <TextInput
                    key={`${requestText}-${wasAllocateRemainingClicked}`}
                    fieldName={displayText}
                    initialValue={`${therms}`}
                    onTextInput={onChangeAllocationDestinationField}
                    htmlId={`${id}-input-field`}
                    hasError={false}
                    type="number"
                    disableNegativeNumbers
                    disableDecimals
                    formatNumberCommas
                />
                <Button
                    type="button"
                    label="Allocate remaining"
                    colorScheme="secondary"
                    size="md"
                    onClick={onAllocateRemainingButtonClick}
                />
            </div>
            {externalErrorFlag && (
                <ValidationMessage
                    id="allocation-destination-too-many-therms-error-message"
                    message="Volume entered exceeds available volume"
                    messageType="error"
                    iconName="warning"
                />
            )}
        </AllocationDestinationContainer>
    );
};

export { AllocationDestinationItem };

import { ACTION_MESSAGES_TYPES, ActionMessage } from '../types';
import { AllocationAligneImportResult, GAS_ALLOCATION_TYPE } from '../../../common/types';
import { AligneImportResult } from '../../../common/types/AligneImportResult';

const isAllocationAligneImportResultSuccessful = (aligneImportResults: AligneImportResult[]): boolean => {
    let isSuccess = true;
    const failedAligneImportResults: AligneImportResult[] = aligneImportResults.filter(
        (aligneImportResult: AligneImportResult) => aligneImportResult.status === 1
    );
    if (failedAligneImportResults.length) {
        isSuccess = false;
    }
    return isSuccess;
};

const getErrorMessage = (aligneImportResults: AligneImportResult[]) => {
    const failedAligneImportResults: AligneImportResult[] = aligneImportResults.filter(
        (aligneImportResult: AligneImportResult) => aligneImportResult.status === 1
    );
    let errorMessage = '';
    if (failedAligneImportResults.length) {
        failedAligneImportResults.forEach((aligneImportResult: AligneImportResult) => {
            if (aligneImportResult.errorMessage) {
                errorMessage += `${aligneImportResult.errorMessage}. `;
            }
        });
    }
    return errorMessage;
};

const generateSuccessMessage = (allocationAligneImportResult: AllocationAligneImportResult) => {
    let message: string;
    const { correlationId } = allocationAligneImportResult;
    switch (allocationAligneImportResult.allocationType) {
        case GAS_ALLOCATION_TYPE.ICE:
            message = `ICE Trade allocation successful for Trade ID ${correlationId}`;
            break;
        case GAS_ALLOCATION_TYPE.INTERNALTRANSFER:
            message = `Internal Transfer successful`;
            break;
        case GAS_ALLOCATION_TYPE.OTC:
            message = `OTC Trade successful`;
            break;
        default:
            message = `This successful transaction is for an unknown allocation type - Trade ID ${correlationId}`;
            break;
    }
    return message;
};

const generateFailureMessage = (allocationAligneImportResult: AllocationAligneImportResult) => {
    let message: string;
    const { correlationId } = allocationAligneImportResult;
    const errorMessage = getErrorMessage(allocationAligneImportResult.importResults);
    switch (allocationAligneImportResult.allocationType) {
        case GAS_ALLOCATION_TYPE.ICE:
            message = `ICE Trade allocation unsuccessful for Trade ID ${correlationId}`;
            break;
        case GAS_ALLOCATION_TYPE.INTERNALTRANSFER:
            message = `Internal Transfer unsuccessful for Trade ID ${correlationId}`;
            break;
        case GAS_ALLOCATION_TYPE.OTC:
            message = `OTC Trade unsuccessful for Trade ID ${correlationId}`;
            break;
        default:
            message = `This unsuccessful transaction is for an unknown allocation type - Trade ID ${correlationId}`;
            break;
    }
    if (errorMessage) {
        message += `. Error message: ${errorMessage}`;
    }
    return message;
};

const mapAligneImportResultToActionMessage = (
    allocationAligneImportResult: AllocationAligneImportResult
): ActionMessage => {
    const aligneImportResults = allocationAligneImportResult.importResults;
    const isSuccess = isAllocationAligneImportResultSuccessful(aligneImportResults);
    const message = isSuccess
        ? generateSuccessMessage(allocationAligneImportResult)
        : generateFailureMessage(allocationAligneImportResult);
    return {
        type: isSuccess ? ACTION_MESSAGES_TYPES.SUCCESS : ACTION_MESSAGES_TYPES.ERROR,
        message
    };
};

export { mapAligneImportResultToActionMessage };

import React from 'react';

import { Header } from '@edfenergy/shift-desk-wallace';
import { HeaderContainer } from './styles';

const NopHeader: React.FC = () => {
    return (
        <HeaderContainer>
            <Header id="NOP_header" size="xs" />
        </HeaderContainer>
    );
};

export { NopHeader };

import styled, { css } from 'styled-components/macro';

const IceTradesAllocationPanelWrapper = styled.div`
    position: sticky;
    bottom: 0;
    background: rgba(255, 255, 255);
    opacity: 1;
`;

const IceTradesAllocationPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 12px;
    & #split-allocation-remaining-amount {
        font-size: 12px;
        font-weight: normal;
        margin: 0px 0px 8px 0px;
        color: #1c1c1c;
        & span {
            font-weight: 600;
            margin: 0px 0px 0px 8px;
        }
    }
    & button {
        float: right;
        margin: 0px 0px 24px 0px;
        width: fit-content;
    }
`;

const IceTradesTotalThermsWrapper = styled.div`
    height: 36px;
    margin: 0px -12px;
    span {
        float: right;
        padding: 10px 12px 9px 0px;
        font-size: 12px;
        letter-spacing: 0.8px;
        font-weight: 600;
    }
    // TODO update Wallace theme and use here.
    background-color: #e5eff8;
`;

const AllocationDestinationElementsContainer = styled.div`
    margin-top: 12px;
`;

const ErrorMessageContainer = styled.div`
    margin: 8px 0px 10px 0px;
    & #ice-trades-allocation-panel-error-message {
        height: 17px;
        font-size: 12px;
        font-weight: 600;
        margin: 0px;
        & #validationMsgIcon {
            height: 16px;
            font-size: 16px;
            margin: 1px 3.3px 0px 0.7px;
        }
    }
`;

const ConfirmButtonWrapper = styled.div``;

const DateCheckboxContainer = styled.div`
    display: flex;
    align-items: flex-end;
    padding: 8px;
`;

export {
    IceTradesTotalThermsWrapper,
    IceTradesAllocationPanelContainer,
    IceTradesAllocationPanelWrapper,
    AllocationDestinationElementsContainer,
    ErrorMessageContainer,
    ConfirmButtonWrapper,
    DateCheckboxContainer
};

import styled from 'styled-components/macro';

const InternalTransfersPanelContainer = styled.div``;

const InternalTransfersPanelHeaderContainer = styled.div`
    margin: 12px;
    & #drawer-trader-id-field {
        display: flex;
        height: 73px;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 25px;
        border-bottom: solid 1px #b9b9b9;
        padding: 0px;
        & span {
            display: none;
        }
    }
`;

const InternalTransfersTimeSelectionContainer = styled.div`
    margin: 25px 12px;
    border-bottom: solid 1px #b9b9b9;
    & label > div:first-of-type {
        padding: 0px;
        margin-bottom: 5px;
    }
    & #internal-transfers-gas-day-field {
        height: 28px;
        width: 152px;
        border-bottom: 1px solid #${({ theme }) => theme.colors.datePicker.internal.border};
        & > div:first-of-type {
            padding: 10px 8px;
            & span {
                color: black;
            }
        }
        & > div:last-of-type {
            z-index: 999;
            left: 0px;
        }
    }
    & #internal-transfers-time-selection-displays-wrapper {
        margin: 12px 0px 25px 0px;
        display: flex;
        & div:last-of-type {
            margin-left: 100px;
        }
    }
`;

const InternalTransfersGasDayFieldContainer = styled.div`
    border: none;
    & #internal-transfers-gas-day-heading {
        color: #${({ theme }) => theme.colors.datePicker.internal.border};
        font-weight: 600;
        font-size: 12px;
        margin: 0px 0px 5px 0px;
        letter-spacing: 0.8px;
    }
    & > span {
        width: fit-content;
    }
    & #datepicker-previous,
    & #datepicker-next {
        display: flex;
    }
`;

const InternalTransfersTimeSelectionDateDisplay = styled.div`
    display: flex;
    flex-direction: column;
    & span {
        :first-of-type {
            color: #${({ theme }) => theme.colors.supportiveText};
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.8px;
        }
        :last-of-type {
            font-weight: 600;
            font-size: 14px;
        }
    }
`;

const InternalTransfersBuySellContainer = styled.div`
    & #internal-transfers-buy-sell-radio-button-group {
        display: flex;
        margin: 12px;
        & label {
            margin: 0px;
            :last-of-type {
                margin-left: 12px;
            }
        }
    }
    & #internal-transfers-segment-selection-wrapper {
        display: flex;
        margin: 12px 12px 25px;
        border-bottom: solid 1px #b9b9b9;
        padding-bottom: 25px;
        justify-content: space-between;
        & span {
            position: relative;
            top: 28px;
            height: 17px;
            width: 75px;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.8px;
        }
    }
    & label > div:first-of-type {
        padding: 0px;
        margin-bottom: 5px;
    }
    & #internal-transfers-right-segment-field,
    & #internal-transfers-left-segment-field {
        height: 28px;
        width: 125px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 0px;
    }
`;

const InternalTransfersSegmentSelectionMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    & p {
        margin: 0px;
        margin-bottom: 5px;
        font-size: 12px;
        color: #${({ theme }) => theme.colors.supportiveText};
        letter-spacing: 0.8px;
        font-weight: 600;
    }
`;

const InternalTransfersVolumeEntryContainer = styled.div`
    display: flex;
    margin: 25px 12px;
    justify-content: space-between;
    & span {
        position: relative;
        top: 23px;
        height: fit-content;
    }
    & label > div:first-of-type {
        padding: 0px;
        margin-bottom: 5px;
    }
    & #internal-transfers-volume-field {
        height: 28px;
        width: 75px;
        border-color: #${({ theme }) => theme.colors.supportiveText};
    }
    & #internal-transfers-price-field {
        height: 28px;
        width: 105px;
        padding-left: 0px;
        border-style: solid solid solid none;
        border-color: #${({ theme }) => theme.colors.supportiveText};
    }
    & #internal-transfers-volume-field + div {
        border: 1px solid #${({ theme }) => theme.colors.supportiveText};
        border-left: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: black;
        padding-right: 3px;
    }
    & #internal-transfers-price-field + div {
        border: 1px solid #${({ theme }) => theme.colors.supportiveText};
        border-right: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        padding-right: 3px;
        min-width: 20px;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 3px;
    }
    & div:has(#internal-transfers-volume-field) {
        margin-bottom: 0px;
    }
    & div:has(#internal-transfers-price-field) {
        flex-direction: row-reverse;
        margin-bottom: 0px;
    }
`;

const InternalTransfersConfirmButtonContainer = styled.div`
    margin: 12px;
    & #internal-transfers-trader-id-error-message,
    & #internal-transfers-price-error-message,
    & #internal-transfers-price-warning-message,
    & #internal-transfers-volume-error-message {
        height: 17px;
        font-size: 12px;
        font-weight: 600;
        margin: 8px 0px 10px 0px;
        & #validationMsgIcon {
            height: 16px;
            font-size: 16px;
            margin: 1px 3.3px 0px 0.7px;
        }
    }
`;

const InternalTransfersConfirmButtonWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    & #internal-transfers-cancel-button {
        margin-right: 12px;
        letter-spacing: 0.8px;
    }
    & #internal-transfers-confirm-button {
        letter-spacing: 0.8px;
    }
`;

export {
    InternalTransfersPanelContainer,
    InternalTransfersPanelHeaderContainer,
    InternalTransfersTimeSelectionContainer,
    InternalTransfersTimeSelectionDateDisplay,
    InternalTransfersGasDayFieldContainer,
    InternalTransfersBuySellContainer,
    InternalTransfersSegmentSelectionMenuWrapper,
    InternalTransfersVolumeEntryContainer,
    InternalTransfersConfirmButtonContainer,
    InternalTransfersConfirmButtonWrapper
};

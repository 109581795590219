import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import { AuthenticationState, UserSession } from './types/AuthenticationState';

const initialState: AuthenticationState = {
    session: {}
};

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setSession: (state, action: PayloadAction<UserSession>) => {
            if (state.session !== action.payload) {
                return { ...state, session: action.payload };
            }
            return state;
        }
    }
});

export const { setSession } = authenticationSlice.actions;

const inputSelectAuthenticationSession = (state: RootState) => state.authentication.session;

export const selectAuthenticationSession = createSelector(
    inputSelectAuthenticationSession,
    (session: UserSession) => session
);

export default authenticationSlice.reducer;

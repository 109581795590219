export type JwtDecodedPayload = {
    iss: string;
    // eslint-disable-next-line camelcase
    client_id: string;
    // eslint-disable-next-line camelcase
    token_use: string;
    // eslint-disable-next-line camelcase
    auth_time: number;
    exp: number;
};

export const checkIsDecodedTokenValid = (decodedToken: JwtDecodedPayload, nowEpoch: number): boolean => {
    const isTokenUseValid = decodedToken.token_use === 'access';
    const isClientIdValid = decodedToken.client_id === process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;
    const poolId = process.env.REACT_APP_USER_POOL_ID || '';
    const isUserPoolIdValid = decodedToken.iss.includes(poolId);
    const isExpired = nowEpoch >= decodedToken.exp;
    return isTokenUseValid && isClientIdValid && isUserPoolIdValid && !isExpired;
};

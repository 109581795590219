/**
 * Returns true if a property is specified and not null. False otherwise.
 *
 * @param prop The property being tested
 * @returns {boolean}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const isSpecified = (prop: any): boolean => {
    return typeof prop !== 'undefined' && prop !== null;
};

/**
 * Returns true if an object is empty. False otherwise.
 *
 * @param obj The object being tested
 * @returns {boolean}
 */
const isEmptyObject = (obj: {}): boolean =>
    obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export { isEmptyObject, isSpecified };

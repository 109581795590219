import { FailureResponseItem, IceTradesPostResponse, OtcTradesPostResponse } from '../../../common/types';
import { ACTION_MESSAGES_TYPES, ActionMessage } from '../types';

const mapOtcTradesApiErrorToActionMessages = (otcTradesPostResponse: OtcTradesPostResponse): ActionMessage[] => {
    let actionMessages: ActionMessage[] = [];
    actionMessages =
        otcTradesPostResponse.data &&
        otcTradesPostResponse.data.failures.map((failureResponseItem: FailureResponseItem) => {
            const message = `OTC Trade unsuccessful for Trade ID ${failureResponseItem.id}. Error message: ${failureResponseItem.failureReason}`;
            return {
                type: ACTION_MESSAGES_TYPES.ERROR,
                message
            };
        });
    return actionMessages;
};

export { mapOtcTradesApiErrorToActionMessages };

import { FailureResponseItem, IceTradesPostResponse, InternalTransfersPostResponse } from '../../../common/types';
import { ACTION_MESSAGES_TYPES, ActionMessage } from '../types';

const mapInternalTransfersApiErrorToActionMessages = (
    internalTransfersPostResponse: InternalTransfersPostResponse
): ActionMessage[] => {
    let actionMessages: ActionMessage[] = [];
    actionMessages =
        internalTransfersPostResponse.data &&
        internalTransfersPostResponse.data.failures.map((failureResponseItem: FailureResponseItem) => {
            const message = `Internal transfer unsuccessful for Trade ID ${failureResponseItem.id}. Error message: ${failureResponseItem.failureReason}`;
            return {
                type: ACTION_MESSAGES_TYPES.ERROR,
                message
            };
        });
    return actionMessages;
};

export { mapInternalTransfersApiErrorToActionMessages };

import styled from 'styled-components';

const HeaderContainer = styled.div`
    & header {
        & svg {
            margin: 5px 0px 5px 0px;
        }
    }
`;

type HeaderToolBarProps = {
    displaySmallScreen?: boolean;
};

const HeaderToolBarContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
`;
const HeaderToolBar = styled.div<HeaderToolBarProps>`
    color: #ffffff;
    flex: 1 1 0px;
    max-width: 10%;
    width: 200px;

    @media only screen and (max-width: 1200px) {
        max-width: 20%;
        width: unset;
    }

    @media only screen and (max-width: 700px) {
        max-width: 52%;
        width: unset;
    }

    ${({ displaySmallScreen }) =>
        displaySmallScreen &&
        `
    @media only screen and (max-width: 700px) {
      display: none;
    }
  `}
`;

export { HeaderContainer, HeaderToolBarContainer, HeaderToolBar };

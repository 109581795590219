import React from 'react';

import { GasNopContainerWrapper, GasNopPanel } from './styles';
import { TradesPanel } from '../../../../common/components/TradesPanel';
import { Footer } from '../Footer';

const GasNopContainer: React.FC = () => {
    return (
        <GasNopContainerWrapper>
            <TradesPanel />
            <GasNopPanel>
                <Footer />
            </GasNopPanel>
        </GasNopContainerWrapper>
    );
};

export default GasNopContainer;

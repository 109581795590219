import React from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isSpecified } from './common/utilities';

import { GasNopContainer } from './features/gasNop/components/GasNopContainer';
import { ConditionalWrapper } from './common/components/ConditionalWrapper';
import { EDFAuth } from './features/authentication/components';
import { NopHeader } from './features/authentication/components/NopHeader';
import { ActionMessagesContainer } from './features/actionMessages/components/ActionMessagesContainer/styles';

const App = () => {
    const isWrappedWithAuth: boolean = isSpecified(process.env.REACT_APP_SKIP_AUTH)
        ? process.env.REACT_APP_SKIP_AUTH === 'false'
        : true;
    return (
        <>
            {!isWrappedWithAuth && <NopHeader />}
            <ConditionalWrapper condition={isWrappedWithAuth} wrapper={(children) => <EDFAuth>{children}</EDFAuth>}>
                <>
                    <ActionMessagesContainer>
                        <ToastContainer />
                    </ActionMessagesContainer>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Navigate to="/gasnop" />} />
                            <Route path="/gasnop" element={<GasNopContainer />} />
                        </Routes>
                    </BrowserRouter>
                </>
            </ConditionalWrapper>
        </>
    );
};

export default App;
